import httpClient from './base-api';

function login(creds) {
    // const url = '/login';
    const url = `/staffs/adminlogin`;
    return httpClient.post(url, creds, {
        userid: creds.id                // adding headers
    });
}

export {
    login
}