import { omitKeysInObj } from 'services/shared-logic.service';

//modifiy
const studentKeys = {
    studentId: 'student_id',
    photo: 'photo',
    firstName: 'firstname',
    lastName: 'lastname',
    parentName: 'parent_name',
    dob: 'dob',
    email: 'email',
    address: 'address',
    mobile: 'mobile',
    alternate_contact : 'alternate_contact',
    preferredFoot: 'preffered_foot',
    batches: 'batches',
    joiningDate: 'joining_date',
    joiningMethod: 'method_of_joining',
    status: 'status',
    bloodGroup: 'blood_group',
    jourseySize:'jersey_size'
}

const studentExcelKeys = omitKeysInObj(studentKeys, ['studentId', 'photo']);

//modify
const studentRowsKeys = [
    'student_id', 'batches', 'firstname', 'lastname', 'preffered_foot', 'mobile', 'alternate_contact', 'blood_group', 'jersey_size','email', 'dob', 'address', 'status'
];
//modify
const studentHeaderKeys = [
    'Player ID', 'Batches', 'First Name', 'Last Name', 'Preffered Foot', 'Mobile No.', 'Alternate Contact', 'Blood Group', 'Joursey Size', 'Email ID', 'Date Of Birth', 'Address', 'Status'
]
const studentUniqueKey = 'student_id';
const studentModalName = 'studentModal';
const transactionModalName = 'transactionModal';

const staffKeys = {
    staffId: 'staff_id',
    photo: 'photo',
    firstName: 'firstname',
    lastName: 'lastname',
    dob: 'dob',
    email: 'email',
    address: 'address',
    mobile: 'mobile',
    designation: 'designation',
    batches: 'batches',
    education: 'education',
    footballEducation: 'football_education',
    role: 'role',
    specialisation: 'specialisation',
    isAdmin: 'isadmin',
    career: 'career',
    joiningDate: 'joining_date',
    joiningMethod: 'method_of_joining',
    bloodGroup: 'blood_group'
}

const staffExcelKeys = omitKeysInObj(staffKeys, ['staffId', 'photo']);

const staffRowsKeys = [
    'staff_id', 'firstname', 'lastname', 'blood_group' ,'dob', 'education', 'football_education', 'role', 'specialisation', 'designation', 'batches', 'mobile', 'email', 'address', 'isadmin'
];
const staffHeaderKeys = [
    'Staff ID', 'First Name', 'Last Name', 'Blood Group','Date Of Birth', 'Education', 'Football Education', 'Role', 'Specialisation', 'Designation', 'Batches', 'Mobile No.', 'Email ID', 'Address', 'Admin'
]
const staffUniqueKey = 'staff_id';
const staffModalName = 'staffModal';

const locationKeys = {
    code: 'code',
    name: 'name',
    mobile: 'mobile',
    email: 'email',
    address: 'address'
}

const locationRowsKeys = [
    'code', 'name', 'mobile', 'email', 'address'
];
const locationHeaderKeys = [
    'Code', 'Location Name', 'Contact No.', 'Email Id', 'Address'
]
const locationUniqueKey = 'code';
const locationModalName = 'locationModal';


const batchKeys = {
    code: 'code',
    locationCode: 'location_code',
    ageGroup: 'age_group',
    capacity: 'capacity',
    dayTime: 'day_time',
    day: 'day',
    startTime: 'starttime',
    endTime: 'endtime',
    sportsName: "sports_name"
}

const batchRowsKeys = [
    'code', 'location_code', 'sports_name', 'age_group', 'capacity'
];
const batchHeaderKeys = [
    'Batch Code', 'Location', 'Sport Associated', 'Age Group', 'Batch Capacity'
]

const rolesKeys = {
    category: 'category',
    accessLinks: 'accesslinks',
}

const rolesUniqueKey = 'category';
const rolesModalName = 'rolesModal';

const rolesRowsKeys = [
    'category', 'accesslinks'
];
const rolesHeaderKeys = [
    'Category', 'Access Links'
]

//Configurations for Products page
const productsKeys = {
    id: 'id',
    category: 'category',
    item: 'item',
    period: 'period',
    discountPercentage: 'discount_percentage',
    hsnCode: 'hsn_code',
    rate: 'rate',
    gstRate: 'gst_rate',
    sgstRate: 'sgst_rate',
    cgstRate: 'cgst_rate',
    igstRate: 'igst_rate'

}
const productsUniqueKey = 'id';
const productsModalName = 'productsModal';

const productsRowsKeys = [
    'id', 'category', 'item', 'period','discount_percentage', 'hsn_code', 'rate', 'gst_rate', 'sgst_rate', 'cgst_rate', 'igst_rate'
];
const productsHeaderKeys = [
    'ID', 'Category', 'Item', 'Period(in months)', 'Discount(%)', 'HSN', 'Rate', 'GST(%)', 'SGST(%)', 'CGST(%)', 'IGST(%)'
]
const productsRowsKeysWithoutGST = [
    'id', 'category', 'item', 'period','discount_percentage', 'rate'
];
const productsHeaderKeysWithoutGST = [
    'ID', 'Category', 'Item', 'Period(in months)', 'Discount(%)', 'Rate'
]

//Configurations for Products page End

//Configurations for Products page Start
const settingsKeys = {
    id: 'id',
    name: 'customername',
    contact: 'contact',
    alternateContact: 'alternate_contact',
    gstin: 'gstin',
    email: 'email',
    logo: 'logo',
    sportsProvided: 'sports_provided',
    address: 'address'
}
const settingsUniqueKey = 'id';
//Configurations for Products page End

const batchUniqueKey = 'code';
const batchModalName = 'batchModal';

const studentAttendanceKeys = {
    studentId: 'student_id',
    batch: 'batch',
    date: 'date',
    status: 'status'
}

const categoryKeys = {
    categoryName: 'category',
    parameters: 'parameters',
    sportsName: 'sports_name'
}

const categoryRowsKeys = [
    'category', 'sports_name','parameters'
];
const categoryUniqueKey = 'category';
const categoryHeaderKeys = [
    'Category', 'Sport Associated', 'Parameters'
]
const categoryModalName = 'categoryModal';

const schoolKeys = {
    code: 'code',
    name: 'name',
    contact: 'contact',
    email: 'email',
    address: 'address',
    sportsType: 'sports_type'
}
const schoolUniqueKey = 'code';
const schoolModalName = 'schoolModal';

const schoolStudentKeys = {
    studentId: 'student_id',
    schoolCode: 'school_code',
    firstName: 'firstname',
    lastName: 'lastname',
    dob: 'dob',
    email: 'email',
    address: 'address',
    contact: 'contact',
    alternateContact : "alternate_contact",
    std: 'std',
    div: 'div',
    joiningDate: 'joining_date',
    bloodGroup: 'blood_group',
}

const schoolStudentExcelKeys = omitKeysInObj(schoolStudentKeys, ['studentId']);

const schoolStudentRowsKeys = [
    'student_id', 'firstname', 'lastname', 'contact', 'alternate_contact', 'blood_group','std', 'div', 'dob','address'
];
const schoolStudentHeaderKeys = [
    'Player ID',  'First Name', 'Last Name', 'Mobile No.', 'Alternate Mobile No.', 'Blood Group', 'Standard', 'Division', 'Date Of Birth','Address'
];
const schoolStudentUniqueKey = 'student_id';
const schoolStudentModalName = 'schoolStudentModal';

const academyAssessmentRowsKeys = ['student_id', 'firstname', 'lastname'];
const academyAssesmentHeaderKeys = ['Player ID',  'First Name', 'Last Name', ]

const nameRegx = RegExp(/^[A-Z ]*$/i);        // only alphabets supported
const numberRegx = RegExp("^[0-9]+$");
const emailRegx = RegExp(/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/);
const mobileRegx = RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/);
const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const backupAuthorizedIds = ["2001", "2002", "2003"];

export {
    studentKeys,
    studentExcelKeys,
    studentRowsKeys,
    studentHeaderKeys,
    studentUniqueKey,
    studentModalName,
    transactionModalName,
    staffKeys,
    staffExcelKeys,
    staffRowsKeys,
    staffHeaderKeys,
    staffUniqueKey,
    staffModalName,
    schoolKeys,
    schoolUniqueKey,
    schoolModalName,
    schoolStudentKeys,
    schoolStudentExcelKeys,
    schoolStudentRowsKeys,
    schoolStudentHeaderKeys,
    schoolStudentUniqueKey,
    schoolStudentModalName,
    locationKeys,
    locationRowsKeys,
    locationHeaderKeys,
    locationUniqueKey,
    locationModalName,
    batchKeys,
    batchRowsKeys,
    batchHeaderKeys,
    batchUniqueKey,
    batchModalName,
    categoryKeys,
    categoryRowsKeys,
    categoryHeaderKeys,
    categoryUniqueKey,
    categoryModalName,
    studentAttendanceKeys,
    nameRegx,
    emailRegx,
    mobileRegx,
    numberRegx,
    weekDays,
    backupAuthorizedIds,
    rolesKeys,
    rolesRowsKeys,
    rolesHeaderKeys,
    rolesModalName,
    rolesUniqueKey,
    academyAssessmentRowsKeys,
    academyAssesmentHeaderKeys,
    productsKeys,
    productsUniqueKey,
    productsModalName,
    productsRowsKeys,
    productsHeaderKeys,
    productsRowsKeysWithoutGST,
    productsHeaderKeysWithoutGST,
    settingsKeys,
    settingsUniqueKey
}