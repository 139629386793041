import Staff from 'components/entities/staff/staff';
import Student from 'components/entities/student/student';
import Location from 'components/entities/location/location';
import Roles from 'components/entities/roles/roles';
import Batch from 'components/entities/batch/batch';
import AttendanceHistory from 'components/history/history';
import Summary from 'components/summary/summary';
import AssessSummary from 'components/assessment-summary/assess-summary';
import Assessment from 'components/entities/assessment/assessment';
import Products from 'components/entities/products/products';
import Tracking from 'components/attendance/attendance-tracking';

const appRoutes = {
    '/student': { name: 'Players', component: Student },
    '/staff': { name: 'Staff', component: Staff },
    '/batch': { name: 'Batches', component: Batch },
    '/location': { name: 'Locations', component: Location },
    '/attendance': { name: 'Attendance', component: AttendanceHistory },
    '/summary': { name: 'Summary', component: Summary },
    '/assess-summary': { name: 'Assessment-History', component: AssessSummary },
    '/assessment': { name: 'Assessment', component: Assessment },
    '/roles': { name: 'Roles', component: Roles },
    '/products': { name: 'Products', component: Products },
    '/tracking': { name:'Tracking', component: Tracking }
}

const organisation = {
    "ORG-1001": {
        name: "Young Guns Football Academy",
        logopath: ["acadlogo.png"],
        transparentLogoPath: ["acadlogo.png"]
    },
    "ORG-1002": {
        name: "Leno Sports",
        logopath: ["lenologo.png"],
        transparentLogoPath: ["lenologo-removebg.png"]
    },
    "ORG-1003": {
        name: "Cosmos Football Academy",
        logopath: ["cosmoslogo.png"],
        transparentLogoPath: ["cosmoslogo.png"]
    },
    "AKJ": {
        name: "Cosmos Football Academy",
        logopath: ["akjoshilogo.png"],
        transparentLogoPath: ["akjoshilogo.png"]
    }
}

export {
    appRoutes,
    organisation
}