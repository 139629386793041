import React from "react";
import $ from 'jquery';
import './assessment.scss';
import Entity from "../entity/entity";
import { categoryHeaderKeys, categoryKeys, categoryModalName, categoryRowsKeys, categoryUniqueKey, settingsKeys } from "config/entity.config";
import { getEntityData, getAssessmentMetadata, modifyAssessmentMetadata, setAssessmentMetadata } from "services/entity-apis";

export default class Assessment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryList: [],
            orgList: [],
            sportsList: [],
            loading: true,
            categoryForm: {
                mode: 'add',
                data: this.initializeCategoryFormData()
            },
        }
    }

    initializeCategoryFormData(category = {}) {
        return {
            [categoryKeys.categoryName]: category[categoryKeys.categoryName] || '',
            [categoryKeys.parameters]: category[categoryKeys.parameters] ? category[categoryKeys.parameters].join(',') : '',
            [categoryKeys.sportsName]: category[categoryKeys.sportsName] || ''
        }
    }

    componentDidMount() {
        this.getAssessmentMeta();
        this.getOrgData();
    }

    async getAssessmentMeta() {
        const data = await getAssessmentMetadata().catch((err) => {
            this.setState({ loading: false })
            console.log(err);
        })

        if (data) {
            await this.setState({
                loading: false,
                categoryList: data.data || []
            })
        }
    }

    async getOrgData() {
        await this.setState({ loading: true })

        const data = await getEntityData('organizations').catch((err) => {
            this.setState({ loading: false })
            console.log(err);
        })

        if (data) {
            await this.setState({
                loading: false,
                orgList: data.data || [],
                sportsList: data.data?.[settingsKeys.sportsProvided] || []
            })
        }
    } 

    async onCategoryFormSubmit(e) {
        e.preventDefault();

        const reqBody = {
            [categoryKeys.categoryName]: this.state.categoryForm.data[categoryKeys.categoryName],
            [categoryKeys.parameters]: this.state.categoryForm.data[categoryKeys.parameters].split(',').map((item) => {
                return item.trim();
            }),
            [categoryKeys.sportsName]: this.state.categoryForm.data[categoryKeys.sportsName] || ''
        }

        let catData;
        if (this.state.categoryForm.mode === 'add') {
            catData = await setAssessmentMetadata(reqBody).catch((err) => {
                console.log(err);
            })
        } else {
            catData = await modifyAssessmentMetadata(reqBody[categoryKeys.categoryName], {
                [categoryKeys.parameters]: reqBody[categoryKeys.parameters],
                [categoryKeys.sportsName]: reqBody[categoryKeys.sportsName]
            }).catch((err) => {
                console.log(err);
            })
        }

        if (catData) {
            $(`#${categoryModalName}`).modal('hide');
            this.getAssessmentMeta();
        }
    }

    handleChange(key, event) {
        const value = event.target.value;

        this.setState({
            categoryForm: {
                mode: this.state.categoryForm.mode,
                data: { ...this.state.categoryForm.data, [key]: value }
            }
        })
    }

    async openAddCatModal() {
        await this.setState({
            categoryForm: {
                mode: 'add',
                data: this.initializeCategoryFormData()
            }
        })
        $(`#${categoryModalName}`).modal('show');
    }

    async editCategory(category) {
        const reqCat = this.state.categoryList.find((item) => item[categoryUniqueKey] === category);
        if (reqCat) {
            // initialize form with prefill data
            await this.setState({
                categoryForm: {
                    mode: 'edit',
                    data: this.initializeCategoryFormData(reqCat)
                }
            });
            $(`#${categoryModalName}`).modal('show');
        }
    }

    render() {
        const { categoryForm, sportsList } = this.state;
        return (
            <>
                <Entity
                    entityRowsKeys={categoryRowsKeys} entityHeaderKeys={categoryHeaderKeys}
                    uniqueKey={categoryUniqueKey} entityName='Category'
                    modalName={categoryModalName} entityDataList={this.state.categoryList}
                    loading={this.state.loading}
                    addEntity={this.openAddCatModal.bind(this)}
                    editEntity={this.editCategory.bind(this)}
                    editAction={false}
                />

                <div className="modal fade" id={categoryModalName} tabIndex="-1" aria-labelledby="CategoryModalLabel" aria-hidden="true">
                    <div className="modal-dialog min-width">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="CategoryModalLabel">Add Category Details</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.onCategoryFormSubmit.bind(this)}>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label htmlFor="category">Category *</label>
                                            <input type="text" className="form-control" id="category" placeholder="Category Name" readOnly={this.state.categoryForm.mode === 'edit'}
                                                required value={categoryForm.data[categoryKeys.categoryName]} onChange={this.handleChange.bind(this, categoryKeys.categoryName)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label htmlFor="sports_name"> Sport *</label>
                                            <select id="sports_name" className="form-control selectpicker" data-live-search="true" required
                                                value={categoryForm.data[categoryKeys.sportsName]} onChange={this.handleChange.bind(this, categoryKeys.sportsName)}
                                            >
                                                <option value=""> Select </option>
                                                {
                                                    sportsList?.map((item) => {
                                                        return <option value={item} key={item}> {item} </option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label htmlFor="parameters">Parameters</label>
                                            <textarea id="parameters" name="parameters" placeholder="Add Comma separated Parameters ..."
                                                value={categoryForm.data[categoryKeys.parameters]} onChange={this.handleChange.bind(this, categoryKeys.parameters)}>
                                            </textarea>
                                        </div>
                                    </div>
                                    <div>
                                        <button className="btn btn-success w-100" type="submit"> Submit </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}