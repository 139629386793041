// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import { faAngleDoubleUp, faAngleLeft, faAngleRight, faArrowLeft, 
  faFilePdf, faPen, faPlusCircle, faUser, faTrash, faFileInvoice, faCommentsDollar, faPlus } from '@fortawesome/free-solid-svg-icons';

library.add(
  faUser,
  faAngleRight,
  faAngleLeft,
  faAngleDoubleUp,
  faFilePdf,
  faArrowLeft,
  faPen,
  faPlusCircle,
  faTrash,
  faFileInvoice,
  faCommentsDollar,
  faPlus
  // more icons go here
);