import React from "react";
import $ from "jquery";
// import "./school-players.scss";
import {
    getEntityData,
    getAssessmentTelemetry,
    getAssessmentMetadata,
    getBatchWiseData,
    getEntityDataByID
} from "services/entity-apis";
import {
    schoolStudentKeys,
    academyAssesmentHeaderKeys,
    academyAssessmentRowsKeys,
    schoolStudentUniqueKey,
    schoolStudentModalName,
    staffKeys
} from "config/entity.config";
import {
    organisation
} from "config/app.config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AssessmentTracking from "components/assessment-tracking/assessment-tracking";
import AssessmentChecking from "components/assessment-check/assessment-check";
const buttonRef = React.createRef();

export default class AcademyPlayers extends React.Component {
    today = +new Date() / 1000;

    constructor(props) {
        super(props);
        const currDate = new Date();
        const currYr = currDate.getFullYear();
        let currMon = currDate.getMonth() + 1;
        currMon = currMon > 9 ? currMon : `0${currMon}`;

        this.state = {
            studentList: [],
            showingStudentList: [],
            studentSearchValue: '',
            loading: true,
            selectedMonth: `${currYr}-${currMon}`,
            assessmentType: [],
            assessQuestions: null,
            assessHistoricalData: [],
            selectedStudent: null,
            entity: "academy",
            selectedStaff: null,
            staffList: [],
            batchInfo : null,
            standard: null
        };
    }

    componentDidMount() {
        const currDate = new Date();
        const startTm = +new Date(currDate.getFullYear(), currDate.getMonth(), 1);
        const endTm = +new Date(currDate.getFullYear(), currDate.getMonth() + 1, 1);

        this.getStudentListBatchWise();
        this.getBatchData();
        // this.getAssessmentMetadata();
        this.getStaffData();
        this.getAssessmentTelemetry(startTm, endTm);
    }

    async getStudentListBatchWise() {
        const stuData = await getBatchWiseData('students', this.props.batch).catch((err) => {
            this.setState({ loading: false });
            console.log(err);
        })

        if (stuData && stuData.data && stuData.data.length) {
            await this.setState({
                loading : false,
                batchStudents: stuData.data || [],
                selectedStudent: null,
                selectedAssessment: null,
                assessInput: null,
                comments: ''
            })
            this.setShowingStudentList();
        }
    }
 
    async getStaffData() {
        const data = await getEntityData('staffs').catch((err) => {
            console.log(err);
        })
        if (data) {
            await this.setState({
                staffList: data.data || []
            })
        }
    }

    async getBatchData() {
        const data = await getEntityDataByID('batches',this.props.batch).catch((err) => {
            console.log(err);
        })
        if (data) {
            await this.setState({
                batchInfo: data.data || []
            })
            this.getAssessmentMetadata();
        }
    }

    async getAssessmentTelemetry(startTm, endTm) {
        const assessData = await getAssessmentTelemetry(Math.floor(startTm / 1000), Math.floor(endTm / 1000)).catch(err => {
            console.log(err);
        })

        if (assessData) {
            await this.setState({
                assessHistoricalData: assessData.data || []
            })
        }
    }

    async getAssessmentMetadata() {
        const assessMetadata = await getAssessmentMetadata().catch(err => {
            console.log(err);
        })
        if (assessMetadata && assessMetadata.data) {
            const assessmentType = [],
                assessQuestions = {};
            assessMetadata.data.forEach(element => {
                if (element.sports_name && (element.sports_name === this.state.batchInfo?.sports_name)) {
                    assessmentType.push(element.category);
                    assessQuestions[element.category] = element.parameters;
                }
            });
            this.setState({
                assessmentType,
                assessQuestions
            })
        }
    }

    onMonthChange(event) {
        const tempArr = event.target.value.split('-');
        const { year, month } = {
            year: tempArr[0],
            month: tempArr[1]
        }
        const firstDay = +new Date(year, month - 1, 1);
        const lastDay = +new Date(year, month, 1);
        this.setState({
            selectedMonth: event.target.value
        })
        this.getAssessmentTelemetry(firstDay, lastDay);
    }

    async trackAssessment(studentId) {
        let selectedStudentData = this.state?.batchStudents?.find((item) => item.student_id === studentId);

        await this.setState({
            selectedStudent: studentId,
            selectedStudentData: selectedStudentData
        });
        this.openChild();
        $(`#track-assessment-modal`).modal("show");
        $('#track-assessment-modal').off('hidden.bs.modal').on('hidden.bs.modal', (event) => {
            this.onMonthChange({target: {value: this.state.selectedMonth}})
        })
    }

    async checkAssessment(studentId) {
        //select staff
        let selectedStaff = null;
        let studentAssessData = this.state?.assessHistoricalData?.find((item) => item.student_id === studentId);

        let selectedStudent = this.state?.batchStudents?.find((item) => item.student_id === studentId);

        await this.setState({
            selectedStudent: selectedStudent,
            studentAssessData: studentAssessData,
            selectedStaff : this.state.staffList?.[0]
        });

        //select staff
        selectedStaff = this.state.staffList.find(item => +item[staffKeys.staffId] === +studentAssessData?.staff_id);
        if (selectedStaff) {
            await this.setState({
                selectedStaff
            })
        }
        $(`#check-assessment-modal`).modal("show");
    }

    handleChange(key, event) {
        let value = event.target.value

        this.setState({
            studentForm: {
                mode: this.state.studentForm.mode,
                data: { ...this.state.studentForm.data, [key]: value },
            },
            keyValChange: { ...this.state.keyValChange, [key]: true },
        });
    }

    async openAddStudentModal() {
        await this.setState({
            studentForm: {
                mode: "add",
                data: this.initializeStudentFormData(),
            },
        });
        $(`#${schoolStudentModalName}`).modal("show");
    }

    async onSearchPlayer(event) {
        await this.setState({
            studentSearchValue: event.target.value
        });
        this.setShowingStudentList();
    }

    async setShowingStudentList() {
        await this.setState({
            showingStudentList: this.state.batchStudents.filter((stu) => {
                return this.state.studentSearchValue ? (
                    (
                        (stu[schoolStudentKeys.firstName] || "").trim() +
                        " " +
                        (stu[schoolStudentKeys.lastName] || "").trim()
                    )
                        .toLowerCase()
                        .includes(this.state.studentSearchValue.toLowerCase())
                ) : true
            })
        })
    }

    handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e);
        }
    };

    handleOnError = (err) => {
        console.log(err);
    };

    createEntityDataTableBody() {
        return this.state.showingStudentList.map((entity, idx) => {
            return (
                <tr className={`${entity.status === 'inactive'? 'row-disabled': (entity.status === 'renewal_pending' ? 'bg-warning-light' : '')}`} key={entity[schoolStudentUniqueKey]}>
                    {academyAssessmentRowsKeys.map((key, index) => {
                        return Array.isArray(entity[key]) ? (
                            <td key={index}> {entity[key].join(", ")} </td>
                        ) : (
                            <td key={index}>
                                {" "}
                                {entity[key] !== undefined && entity[key] !== null
                                    ? entity[key].toString()
                                    : null}
                            </td>
                        );
                    })}
                    {(entity.status !== 'inactive') ? <td>
                        <button
                            type="button"
                            className="btn hover-scale shadow-none border-0"
                            title="Track Assessment"
                            onClick={this.trackAssessment.bind(this, entity[schoolStudentUniqueKey])}
                        >
                            <FontAwesomeIcon icon={["fas", "plus-circle"]} color="green"/>
                        </button>
                        <button
                            type="button"
                            className="btn hover-scale shadow-none border-0"
                            title="Check Assessment"
                            disabled={!this.state.assessHistoricalData.find((item) => item.student_id === entity[schoolStudentUniqueKey])}
                            onClick={this.checkAssessment.bind(this, entity[schoolStudentUniqueKey])}
                        >
                            <FontAwesomeIcon icon={["fas", "file-pdf"]} color="red" />
                        </button>
                    </td> : <td></td>}
                </tr>
            );
        });
    }

    createEntityDataTableHeader() {
        return (
            <tr>
                {academyAssesmentHeaderKeys.map((key, index) => {
                    return (
                        <th scope="col" key={index}>
                            {" "}
                            {key}{" "}
                        </th>
                    );
                })}
                <th scope="col"> Actions </th>
            </tr>
        );
    }

    render() {
        let mainDiv;
        if (this.state.loading) {
            mainDiv = (
                <div className="d-flex justify-content-center p-4">
                    <div id="preloader">
                        <div id="loader"></div>
                    </div>
                </div>
            );
        } else {
            mainDiv = (
                <div className="table-responsive">
                    <table className="table">
                        <thead className="thead-dark">
                            {this.createEntityDataTableHeader()}
                        </thead>
                        <tbody>{this.createEntityDataTableBody()}</tbody>
                    </table>
                </div>
            );
        }

        const { showingStudentList, selectedMonth, assessmentType, assessQuestions,
            assessHistoricalData, selectedStudent, selectedStudentData } = this.state;
        return (
            <>
                <div className="mt-2 px-3">
                    <div className="d-flex justify-content-between flex-wrap mb-4">
                        <div className="d-flex align-items-center flex-wrap">
                            <div className="entity-title">
                                {" "}
                                Players ({showingStudentList.length}){" "}
                            </div>

                            <div className="search-player ml-4">
                                <input
                                    type="search"
                                    className="input-grey-rounded"
                                    placeholder="Search By Player Name"
                                    onInput={this.onSearchPlayer.bind(this)}
                                />
                            </div>
                            <div className="ml-2">
                                <input type="month" className="form-control" onChange={this.onMonthChange.bind(this)} value={selectedMonth} />
                            </div>
                            {/* <div className="form-check mt-2">
                                <input className="form-check-input" type="checkbox" name="showGoalKeeping" id="showGoalKeeping" checked={isGoalKeeping === 'true'}
                                    value={isGoalKeeping === 'true' ? 'false' : 'true'}
                                    onChange={() => { this.setState({ isGoalKeeping: isGoalKeeping === 'true' ? 'false' : 'true' }) }}
                                />
                                <label className="form-check-label" htmlFor="showGoalKeeping">
                                    Show GoalKeeping?
                                </label>
                            </div> */}
                        </div>
                    </div>
                    {mainDiv}
                </div>

                <AssessmentTracking assessmentType={assessmentType} assessQuestions={assessQuestions}
                    assessHistoricalData={assessHistoricalData} selectedStudent={selectedStudent} selectedStudentData={selectedStudentData}
                    entity={this.state.entity} setOpen={open => this.openChild = open} />
                <AssessmentChecking studentAssessData={this.state.studentAssessData} selectedStudent={this.state.selectedStudent}
                    month={this.state.selectedMonth} selectedStaff={this.state.selectedStaff} organisation={organisation[sessionStorage.getItem("org_id")]}
                    entity={this.state.entity} batchCode={this.props.batch} isGoalKeeping={this.state.isGoalKeeping}
                />
            </>
        );
    }
}
