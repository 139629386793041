import React, { createRef } from "react";
import $ from "jquery";
import './assessment-tracking.scss';
import {
    addSchoolAssessmentData,
    addStudentAssessmentData,
    patchAssessmentTelemetry,
    patchSchoolAssessmentTelemetry,
     } from "services/entity-apis";
import { errorToast, successToast } from "services/shared-logic.service";

const ACADEMY = 'academy';
const SCHOOL = 'school';

export default class AssessmentTracking extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            assessInput: null,
            selectedAssessment: null,
            comments: '',
            isAlreadyCompleted: null,
            assessmentTabRef: createRef(),
            paramsInputRef: createRef()
        };
    }

    componentDidMount() {
        this.props.setOpen(this.onOpen.bind(this));
    }

    async onOpen() {
        await this.setState({
            selectedAssessment: null,
            assessInput: null,
            comments: ''
        })
        this.handleStudentSelect();
    }

    async handleStudentSelect() {
        let historicalData = this.props?.assessHistoricalData?.find((item) => item.student_id === this.props?.selectedStudent);
        await this.setState({
            assessInput: this.props?.assessmentType?.reduce((prev, curr) => {
                prev[curr] = (this.props?.assessQuestions?.[curr] || []).map((item) => {
                    return {
                        question: item,
                        value: (historicalData && historicalData.data[curr] && historicalData.data[curr][item]) ? historicalData.data[curr][item] : 2
                    }
                });
                return prev;
            }, {}),
            comments: historicalData ? historicalData.comments : '',
            isAlreadyCompleted: historicalData ? true : false
        })

        // scroll tabs to start position
        this.state.assessmentTabRef?.current?.scroll({ top: 0, left: 0, behavior: 'smooth' })
        this.state.paramsInputRef?.current?.scroll({ top: 0, left: 0, behavior: 'smooth' })

        this.handleAssessType(this.props?.assessmentType?.[0]);
    }

    async handleAssessType(item) {
        await this.setState({
            selectedAssessment: item
        })
    }

    handleChange(key, event) {
        this.setState({
            [key]: event.target.value
        })
    }

    handleGradeType(item, event) {
        const tempAssessInput = this.state.assessInput;
        tempAssessInput[this.state.selectedAssessment].map((ques) => {
            if (ques.question === item.question) {
                ques.value = event
            }
            return ques;
        })
        this.setState({
            assessInput: tempAssessInput
        })
    }

    async handleSubmitData() {
        let res = null;
        if (this.state.isAlreadyCompleted) {
            this.handleEditData(this.props?.selectedStudent);
            return;
        }
        const tempData = JSON.parse(JSON.stringify(this.state.assessInput));
        this.props?.assessmentType?.forEach(element => {
                tempData[element] = tempData[element].reduce((prev, curr) => {
                    prev[curr.question] = curr.value;
                    return prev;
                }, {})
        });

        const body = {
            staff_id: +sessionStorage.getItem("userId"),
            student_id: this.props?.selectedStudent,
            data: tempData,
            time: Math.floor(+new Date() / 1000),
            comments: this.state.comments
        }

        switch (this.props.entity) {
            case ACADEMY:
                res = await addStudentAssessmentData(body).catch(err => {
                    console.log(err);
                    errorToast(err);
                    $(`#track-assessment-modal`).modal("hide");
                })
                break;
            case SCHOOL:
                res = await addSchoolAssessmentData(body).catch(err => {
                    console.log(err);
                    errorToast(err);
                    $(`#track-assessment-modal`).modal("hide");
                })
                break;
            default:
                console.log("Wrong Entity Received")
                errorToast("Wrong Entity Received for Update")
        }

        if (res) {
            successToast("Assessment submitted successfully!");
            $(`#track-assessment-modal`).modal("hide");
        }
    }

    async handleEditData(studentId) {
        let historicalData = this.props?.assessHistoricalData?.find((item) => item.student_id === studentId);
        let res = null;
        const body = {
            time: historicalData.time,
            comments: this.state.comments
        }
        switch (this.props.entity) {
            case ACADEMY:
                res = await patchAssessmentTelemetry(studentId, body).catch(err => {
                    console.log(err);
                    errorToast(err);
                    $(`#track-assessment-modal`).modal("hide");
                })
                break;
            case SCHOOL:
                res = await patchSchoolAssessmentTelemetry(studentId, body).catch(err => {
                    console.log(err);
                    errorToast(err);
                    $(`#track-assessment-modal`).modal("hide");
                })
                break;
            default:
                console.log("Wrong Entity Received")
                errorToast("Wrong Entity Received for Update")
        }

        if (res) {
            successToast("Assessment edited successfully!");
            $(`#track-assessment-modal`).modal("hide");
        }
    }

    render() {
        return (
            <div
                className="modal fade"
                id="track-assessment-modal"
                tabIndex="-1"
                aria-labelledby="TrackAssessmentModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="TrackAssessmentModalLabel">
                                {this.props.selectedStudentData?.firstname} {this.props.selectedStudentData?.lastname }'s Assessment
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <section className="container wrapper">
                                <section className="main-body">
                                    {this.props?.selectedStudent ? <section className="assessment-body">
                                        <section className="assessment-tabs" ref={this.state.assessmentTabRef}>
                                            {
                                                this.props?.assessmentType?.map((item) => {
                                                    return (
                                                        <div className={`tab-item col-6 col-md-3 ${item === this.state.selectedAssessment ? 'active' : ''}`}
                                                            onClick={this.handleAssessType.bind(this, item)} key={item}>
                                                            {item}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </section>
                                        <section className="params-input-body">
                                            {(this.state.assessInput && this.state.selectedAssessment) ? <section className="input-questions" ref={this.state.paramsInputRef}>
                                                {
                                                    this.state.assessInput[this.state.selectedAssessment].map((item) => {
                                                        return (
                                                            <div className="question" key={item.question}>
                                                                <div className="ques-title"> {item.question} </div>
                                                                <div className="ques-opt d-flex">
                                                                    <button disabled={this.state.isAlreadyCompleted} className={item.value === 1 ? 'active' : ''} onClick={this.handleGradeType.bind(this, item, 1)}> Needs Improvement </button>
                                                                    <button disabled={this.state.isAlreadyCompleted} className={item.value === 2 ? 'active' : ''} onClick={this.handleGradeType.bind(this, item, 2)}> Improving </button>
                                                                    <button disabled={this.state.isAlreadyCompleted} className={item.value === 3 ? 'active' : ''} onClick={this.handleGradeType.bind(this, item, 3)}> Excellent </button>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </section> : null}
                                            <section className="comment-sec">
                                                <div className="form-group col-md-12">
                                                    <textarea id="comments" name="comments" placeholder="Coach feedback..."
                                                        value={this.state.comments} onChange={this.handleChange.bind(this, 'comments')}>
                                                    </textarea>
                                                </div>
                                            </section>
                                        </section>
                                    </section> : null}
                                </section>
                            </section>
                            <div>
                                <button className="btn btn-success w-100" type="button" onClick={this.handleSubmitData.bind(this)}>
                                    {" "}
                                    Submit{" "}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}