import axios from 'axios';
import { environment } from 'environment';
import $ from 'jquery';
import { checkModalAlreadyOpen } from 'services/shared-logic.service';

/** Request and Response Interceptors */

// request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent

    if (sessionStorage.getItem('token')) {
        config.headers['Authorization'] = sessionStorage.getItem('token');
        config.headers['userid'] = sessionStorage.getItem("userId");
    }
    if (sessionStorage.getItem('org_id') && !config.data?.org_id) {
        config.params = { ...config.params, org_id: sessionStorage.getItem('org_id') }
    }
    if (config.method === 'post') {
        if (Array.isArray(config.data) && Object.keys(config.data?.[0]).length) {
            config.data = config.data.map(item => { item.org_id = sessionStorage.getItem('org_id'); return item; })
        } else if ((typeof config.data === 'object') && (config.data !== null)) {
            config.data = { ...config.data, org_id: sessionStorage.getItem('org_id') }
        }
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response && error.response.status === 401) {
        sessionStorage.removeItem("token");
        if (!checkModalAlreadyOpen('loginModal')) {
            $('#loginModal').modal({ backdrop: 'static' })
        }
    }
    return Promise.reject(error);
});


// setting axios base url
axios.defaults.baseURL = environment.baseUrl;

const httpClient = {
    get: (url) => {
        return axios({
            url,
            method: 'GET'
        })
    },

    post: (url, data, headers) => {
        return axios({
            method: 'POST',
            url,
            headers: headers ? headers : {
                'Content-Type': 'application/json'
            },
            data: data
        })
    },

    delete: (url, data) => {
        return axios({
            method: 'DELETE',
            url,
            data: data
        })
    },

    patch: (url, data) => {
        return axios({
            method: 'PATCH',
            url,
            data: data
        })
    }
};

export default httpClient;