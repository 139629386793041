import React from "react";
import ReactDOMServer from 'react-dom/server';
import htmlToPdf from 'html-to-pdf-js';
import './assessment-check.scss';
import { studentKeys } from 'config/entity.config';
import AssessPdf from "components/assessment-summary/assessment-pdf/assess-pdf";

export default class AssessmentChecking extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isGoalKeeping : false
        };
    }

    downloadPdf() {
        const input = ReactDOMServer.renderToStaticMarkup(<AssessPdf filteredAssessmentData={this.props.studentAssessData}
        student={this.props.selectedStudent} month={this.props.month} staff={this.props.selectedStaff}
        organisation={this.props.organisation} entity={this.props.entity} batchCode={this.props?.batchCode} isGoalKeeping={this.state.isGoalKeeping}
        />)
        var opt = {
            margin: 1,
            filename: `QA_${this.props.selectedStudent[studentKeys.firstName]}_${this.props.selectedStudent[studentKeys.lastName]}_${this.props.month}.pdf`,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: {
                dpi: 192,
                scale: 4,
                letterRendering: true,
                useCORS: true
            },
            pagebreak: { before: '.assess-footer' }, 
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };
        htmlToPdf(input, opt)

    }

    render() {
        const {isGoalKeeping} = this.state
        return (
            <div
                className="modal fade"
                id="check-assessment-modal"
                tabIndex="-1"
                aria-labelledby="FillAssessmentModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-sm">
                    <div className="modal-content custom-width m-auto">
                        <div className="modal-header">
                            <h5 className="modal-title" id="FillAssessmentModalLabel">
                                Player's Assessment
                            </h5>
                            {
                            (this.props.selectedStudent && this.props.studentAssessData)
                                ?   <div className="ml-2">
                                        <button type="button" className="ml-2 btn btn-outline-primary"
                                            onClick={this.downloadPdf.bind(this)}
                                            >Download PDF
                                        </button>
                                    </div>
                                : null
                            }
                            {(this.props.entity === "academy")
                            ? <div className="form-check ">
                                <input className="form-check-input ml-2" type="checkbox" name="showGoalKeeping" id="showGoalKeeping" checked={isGoalKeeping === 'true'}
                                    value={isGoalKeeping === 'true' ? 'false' : 'true'}
                                    onChange={() => { this.setState({ isGoalKeeping: isGoalKeeping === 'true' ? 'false' : 'true' }) }}
                                />
                                <label className="form-check-label ml-4" htmlFor="showGoalKeeping">
                                    Show GoalKeeping?
                                </label>
                            </div>
                            : null}
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {(this.props.selectedStudent && this.props.studentAssessData && this.props.selectedStaff)
                            ? <AssessPdf filteredAssessmentData={this.props.studentAssessData} student={this.props.selectedStudent}
                              month={this.props.month} staff={this.props.selectedStaff} organisation={this.props.organisation}
                              entity={this.props.entity} batchCode={this.props?.batchCode} isGoalKeeping={this.state.isGoalKeeping}
                            /> : "No data available"}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}