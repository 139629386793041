import httpClient from './base-api';
import { convertObjToQueryStr } from 'services/shared-logic.service';

function getEntityData(entity) {
    return httpClient.get(`/${entity}`);
}

function getAllEntitiesData(entity) {
    return httpClient.get(`/${entity}`);
}

function getEntityDataByID(entity, entityId) {
    return httpClient.get(`/${entity}/${entityId}`);
}

function addEntityData(entity, jsonData) {
    return httpClient.post(`/${entity}`, jsonData);
}

function patchEntity(entity, entityId, patchData) {
    return httpClient.patch(`/${entity}/${entityId}`, patchData)
}

function deleteEntity(entity, entityId) {
    return httpClient.delete(`/${entity}/${entityId}`)
}

function entityPhotoPatch(entity, entityId, formData) {
    return httpClient.patch(`/${entity}/upload_photo/${entityId}`, formData);
}

function fetchStudentAttendance(obj) {
    const queryStr = convertObjToQueryStr(obj);
    return httpClient.get(`/student_attendance${queryStr !== '' ? ('?' + queryStr) : ''}`);
}

function submitStudentAttendance(data){
    return httpClient.post(`/student_attendance`,data);
}

function updateStudentAttendance(data){
    return httpClient.patch(`/student_attendance/update_attendance`,data);
}

function getBatchData(code) {
    return httpClient.get(`/batches/${code}`);
}

function getBatchWiseData(entity, batchCode) {
    return httpClient.get(`/${entity}/batch/${batchCode}`);
}

function getStudentSummary(studentId, to, from) {
    return httpClient.get(`/student_attendance/attendance_summary?id=${studentId}&to=${to}&from=${from}`);
}

function getBatchSummary(batchCode, to, from) {
    return httpClient.get(`/student_attendance/batchWiseAttendance?batch=${batchCode}&to=${to}&from=${from}`);
}

function addPaymentEntry(body) {
    return httpClient.post(`/transactions`, body);
}

function getStudentTransactionHistory(studentId) {
    return httpClient.get(`/transactions/student/${studentId}`);
}

function getAssessmentTelemetry(strtTm, endTm) {
    return httpClient.get(`/student_assessment${strtTm ? ('?starttime=' + strtTm + '&endtime=' + endTm) : ''}`)
}

function getAssessmentMetadata() {
    return httpClient.get('/assessment_metadata');
}

function addStudentAssessmentData(body) {
    return httpClient.post('/student_assessment', body);
}

function patchAssessmentTelemetry(studentId, body) {
    return httpClient.patch(`/student_assessment/${studentId}`, body);
}

function addSchoolAssessmentData(body) {
    return httpClient.post('/schools_assessment', body);
}

function getSchoolsAssessment(strtTm, endTm) {
    return httpClient.get(`/schools_assessment${strtTm ? ('?starttime=' + strtTm + '&endtime=' + endTm) : ''}`)
}

function patchSchoolAssessmentTelemetry(studentId, body) {
    return httpClient.patch(`/schools_assessment/${studentId}`, body);
}

function setAssessmentMetadata(body) {
    return httpClient.post('/assessment_metadata', body)
}

function modifyAssessmentMetadata(cat, body) {
    return httpClient.patch(`/assessment_metadata/${cat}`, body)
}

function backupDownload() {
    return httpClient.get(`/staffs/backup`);
}

function getCompletePaymentData() {
    return httpClient.get(`/transactions`);
}

function getAccessPermissions(api, id) {
    return httpClient.get(`${api}/${id}`);
}

export {
    getEntityData,
    getAllEntitiesData,
    getEntityDataByID,
    addEntityData,
    patchEntity,
    entityPhotoPatch,
    deleteEntity,
    fetchStudentAttendance,
    submitStudentAttendance,
    updateStudentAttendance,
    getBatchData,
    getBatchWiseData,
    getStudentSummary,
    getBatchSummary,
    addPaymentEntry,
    getStudentTransactionHistory,
    getAssessmentTelemetry,
    getAssessmentMetadata,
    setAssessmentMetadata,
    addStudentAssessmentData,
    patchAssessmentTelemetry,
    modifyAssessmentMetadata,
    backupDownload,
    getCompletePaymentData,
    getAccessPermissions,
    addSchoolAssessmentData,
    getSchoolsAssessment,
    patchSchoolAssessmentTelemetry
}