import React from 'react';
import $ from 'jquery';
import { getEntityDataByID } from "services/entity-apis";
import HistoryModal from '../attendance-history-table/history-modal';
import './history.scss';
import ErrorMsg from 'components/shared/error-msg';

export default class AttendanceHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            batchesAssigned: [],
            batch: null
        }
    }

    componentDidMount() {
        this.getBatchesAssessinedToStaff();
    }

    async getBatchesAssessinedToStaff() {
        const staffData = await getEntityDataByID('staffs', sessionStorage.getItem("userId")).catch(err => {
            console.log(err);
        })

        if (staffData) {
            this.setState({
                batchesAssigned: staffData.data?.batches || []
            })
        }
    }

    async onBatchSelect(batch) {
        await this.setState({ batch });
        this.openChild();
        $('#historyModal').modal('toggle');
    }

    render() {
        return (
            <>
                <div className="container mt-2">
                    <div className="batch-cards row">
                        {
                            this.state.batchesAssigned?.map((item) => {
                                return (
                                    <div className="col-6 col-md-4" key={item}>
                                        <div className="batch-card">
                                            <div className="action-btns d-flex justify-content-around w-100 ">
                                                <button className="action-btns" onClick={this.onBatchSelect.bind(this, item)} key={item}>{item} </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {!this.state.batchesAssigned?.length &&
                        <div style={{ marginTop: '20px' }}>
                            <ErrorMsg msg="No Batch to show !" />
                        </div>
                    }
                </div>
                <HistoryModal batch={this.state.batch} setOpen={open => this.openChild = open} />
            </>
        )
    }
}