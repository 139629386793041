import React from "react";
import $ from 'jquery';
import './location.css'
import Entity from "components/entities/entity/entity";
import { getEntityData, addEntityData, patchEntity, deleteEntity } from 'services/entity-apis';
import { locationKeys, locationHeaderKeys, locationRowsKeys, locationUniqueKey, locationModalName, nameRegx, emailRegx, mobileRegx } from 'config/entity.config';
import { formatDate, validateForm, convertEntityExcelData } from 'services/shared-logic.service';

export default class Location extends React.Component {

    // minJoiningDate;
    // maxJoiningDate;

    constructor(props) {
        super(props);
        // this.setMinMaxJoiningDate();
        this.state = {
            locationList: [],
            loading: true,
            oldCode : null,
            locationForm: {
                mode: 'add',
                data: this.initializeLocationFormData()

            },
            errors: {
                code: '',
                name: '',
                email: '',
                mobile: ''
            },
            keyValChange: this.resetChangedKeys()
        }

    }

    setMinMaxJoiningDate() {
        var d = new Date();
        d.setDate(d.getDate() - 7);
        this.minJoiningDate = formatDate(d);

        this.maxJoiningDate = formatDate(new Date());
    }

    initializeLocationFormData(location = {}) {
        return {
            [locationKeys.code]: location[locationKeys.code] || '',
            [locationKeys.name]: location[locationKeys.name] || '',
            [locationKeys.mobile]: location[locationKeys.mobile] || '',
            [locationKeys.email]: location[locationKeys.email] || '',
            [locationKeys.address]: location[locationKeys.address] || ''
        }
    }

    resetChangedKeys() {
        return {
            [locationKeys.code]: false,
            [locationKeys.name]: false,
            [locationKeys.email]: false,
            [locationKeys.address]: false,
            [locationKeys.mobile]: false,
        }
    }

    componentDidMount() {
        this.getLocationData();
    }

    async getLocationData() {
        await this.setState({ loading: true })

        const data = await getEntityData('locations').catch((err) => {
            this.setState({ loading: false })
            console.log(err);
        })

        if (data) {
            await this.setState({
                loading: false,
                locationList: data.data || []
            })
        }

        // await this.setState({ loading: true })

        // const data = await getEntityData('locations').catch((err) => {
        //     this.setState({ loading: false })
        //     console.log(err);
        // })

        // if (data) {
        //     await this.setState({
        //         loading: false,
        //         locationList: data.data
        //     })
        // }

    }

    handleChange(key, event) {
        this.validateFormData(event.target.id, event.target.value);
        const value = key !== locationKeys.batches ? event.target.value :
            Array.from(event.target.selectedOptions, option => option.value);

        this.setState({
            locationForm: {
                mode: this.state.locationForm.mode,
                data: { ...this.state.locationForm.data, [key]: value }
            },
            keyValChange: { ...this.state.keyValChange, [key]: true }
        })
    }

    validateFormData(id, value) {
        switch (id) {
            case locationKeys.code:
                this.setState({
                    errors: {
                        ...this.state.errors, code: nameRegx.test(value)
                            ? ''
                            : 'Please Enter a valid code'
                    }
                });
                break;
            case locationKeys.name:
                this.setState({
                    errors: {
                        ...this.state.errors, name: nameRegx.test(value)
                            ? ''
                            : 'Please Enter a valid name'
                    }
                });
                break;
            case locationKeys.email:
                this.setState({
                    errors: {
                        ...this.state.errors, email: emailRegx.test(value)
                            ? ''
                            : 'Please Enter Valid Email ID'
                    }
                });
                break;
            case locationKeys.mobile:
                this.setState({
                    errors: {
                        ...this.state.errors, mobile: mobileRegx.test(value)
                            ? ''
                            : 'Please Enter Valid Mobile Number'
                    }
                });
                break;
            default:
                console.log('Validation Not Required!')
        }
    }

    async onLocationFormSubmit(e) {
        e.preventDefault();

        if (!validateForm(this.state.errors)) {
            return;
        }

        const reqData = { ...this.state.locationForm.data };

        for (const [key, value] of Object.entries(reqData)) {
            if (typeof value === 'string') {
                reqData[key] = value.trim();
            }
        }

        if (this.state.locationForm.mode === 'add') {
            // delete reqData[locationKeys.code];
            const locationData = await addEntityData('locations', reqData).catch((err) => {
                console.log(err);
            })
            if (locationData && locationData.data[1]) { //MTK for checking if a location code already exists in the database
                $(`#${locationModalName}`).modal('hide');
                this.getLocationData();
                // this.patchStaffPhoto(staffData.data[staffKeys.staffId])
            }
            else{
                alert("Location Code already exists!")
            }
        } else if (this.state.locationForm.mode === 'edit') {
            const patchableData = {};
            Object.keys(reqData).forEach((item) => {
                if (this.state.keyValChange[item]) {
                    patchableData[item] = reqData[item];
                }
            })
            // const locationData = await patchEntity('locations', reqData[locationKeys.code], patchableData).catch((err) => {
            const locationData = await patchEntity('locations', this.state.oldCode, patchableData).catch((err) => {
                console.log(err);
            })
            if (locationData) {
                $(`#${locationModalName}`).modal('hide');
                this.getLocationData();
                // this.patchStaffPhoto(staffData.data[staffKeys.staffId])
            }

            // this.patchStaffPhoto(reqData[staffKeys.staffId])
        }

    }
    // async onLocationFormSubmit(e) {
    //         e.preventDefault();

    // /*        const form = new FormData();
    //         Object.keys(this.state.locationFormData).forEach((key) => {
    //             if (key === locationKeys.photo && this.state.locationFormData[locationKeys.photo].current.files[0]) {
    //                 // console.log(this.state.locationFormData[locationKeys.photo].current.files[0])
    //                 // form.append("photo", this.state.locationFormData[locationKeys.photo].current.files[0])
    //             } else {
    //                 form.append(key, this.state.locationFormData[key])
    //             }
    //         })
    // */
    //         // temp
    //         // form.append("time_of_leave", new Date())
    //         const data = await addLocation(this.state.locationFormData).catch((err) => {
    //             console.log(err);
    //         })

    //         if (data) {
    //             $(`#${modalName}`).modal('hide');
    //             this.getLocationData();
    //         }
    // }

    async openAddLocationModal() {
        await this.setState({
            locationForm: {
                mode: 'add',
                data: this.initializeLocationFormData()
            }
        })
        $(`#${locationModalName}`).modal('show');
    }

    async deleteLocation(code) {
        let confirm = window.confirm("Are you sure want to delete this entry!");
        if (confirm) {
            const resData = await deleteEntity('locations', code).catch((res) => {
                console.log(res);
            })

            if (resData) {
                console.log(resData);
                this.getLocationData();
            }
        }

        // let confirm = window.confirm("Are you sure want to delete this entry!");
        // if (confirm) {
        //     const resData = await deleteStudent(studentId).catch((res) => {
        //         console.log(res);
        //     })

        //     if (resData) {
        //         console.log(resData);
        //         this.getLocationData();
        //     }
        // }
    }

    async editLocation(code) {
        // get student data using student id
        const reqStu = this.state.locationList.find((item) => item[locationUniqueKey] === code);
        this.setState({oldCode:code})
        if (reqStu) {
            // initialize form with prefill data
            await this.setState({
                locationForm: {
                    mode: 'edit',
                    data: this.initializeLocationFormData(reqStu)
                },
                keyValChange: this.resetChangedKeys()
            });
            $(`#${locationModalName}`).modal('show');
        }

    }

    async handleBulkInsert(excelData) {
        await this.setState({ loading: true })
        const studentData = await addEntityData('locations', convertEntityExcelData(excelData)).catch((err) => {
            console.log(err);
        })
        if (studentData) {
            this.getLocationData();
        }
    }

    render() {
        const { errors, locationForm } = this.state;
        return (
            <>
                <Entity
                    entityRowsKeys={locationRowsKeys} entityHeaderKeys={locationHeaderKeys}
                    uniqueKey={locationUniqueKey} entityName='location'
                    modalName={locationModalName} entityDataList={this.state.locationList}
                    entityExcelKeys={locationKeys} loading={this.state.loading}
                    // addBtnDisabled={this.batchList.length > 0 ? false : true}
                    addEntity={this.openAddLocationModal.bind(this)}
                    deleteEntity={this.deleteLocation.bind(this)}
                    editEntity={this.editLocation.bind(this)}
                    handleBulkInsert={this.handleBulkInsert.bind(this)}
                    editAction={true}
                />

                <div className="modal fade" id={locationModalName} tabIndex="-1" aria-labelledby="LocationModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="LocationModalLabel">Add Location Details</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.onLocationFormSubmit.bind(this)}>
                                    {/*                                    <div style={{ marginBottom: '16px' }}>
                                        <div style={{ marginBottom: '8px' }}> Photo </div>
                                        <input type="file" accept=".png, .jpg" className="form-control-file" id="studentPhoto"
                                            ref={this.state.locationFormData[locationKeys.photo]}></input>
                                    </div>

                                        <div className="form-group col-md-5">
                                            <label htmlFor="firstname">First Name</label>
                                            <input type="text" className="form-control" id="firstname" placeholder="First Name"
                                                value={this.state.locationFormData[locationKeys.firstName]} onChange={this.handleChange.bind(this, locationKeys.firstName)}
                                            />
                                        </div>
                                        */}
                                    <div className="form-row">
                                        <div className="form-group col-md-7">
                                            <label htmlFor="lastname">Location Name*</label>
                                            <input type="text" className="form-control" id="name" placeholder="Location name" maxLength="30" required
                                                value={locationForm.data[locationKeys.name]} onChange={this.handleChange.bind(this, locationKeys.name)}
                                            />
                                            {errors.name.length > 0 &&
                                                <span className='error'>{errors.name}</span>}
                                        </div>
                                        <div className="form-group col-md-5">
                                            <label htmlFor="code">Code*</label>
                                            <input type="text" maxLength="5" className="form-control" id="code" placeholder="Code" required
                                                value={locationForm.data[locationKeys.code]} onChange={this.handleChange.bind(this, locationKeys.code)}
                                            />
                                            {errors.code.length > 0 &&
                                                <span className='error'>{errors.code}</span>}
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="mobile">Mobile*</label>
                                            <input type="text" className="form-control" id="mobile" placeholder="Mobile No." required
                                                value={locationForm.data[locationKeys.mobile]} onChange={this.handleChange.bind(this, locationKeys.mobile)}
                                            />
                                            {errors.mobile.length > 0 &&
                                                <span className='error'>{errors.mobile}</span>}

                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="email">Email</label>
                                            <input type="email" className="form-control" id="email" placeholder="Email"
                                                value={locationForm.data[locationKeys.email]} onChange={this.handleChange.bind(this, locationKeys.email)}
                                            />
                                            {errors.email.length > 0 &&
                                                <span className='error'>{errors.email}</span>}

                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="address">Address*</label>
                                        <input type="text" className="form-control" id="address" placeholder="1234 Main St" maxLength="50" required
                                            value={locationForm.data[locationKeys.address]} onChange={this.handleChange.bind(this, locationKeys.address)}
                                        />
                                    </div>
                                    <div>
                                        <button className="btn btn-success w-100" type="submit"> Submit </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}