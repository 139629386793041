import React from "react";
import $ from "jquery";
import "./student.css";
import Entity from "components/entities/entity/entity";
import {
  getEntityData,
  addEntityData,
  patchEntity,
  deleteEntity,
  entityPhotoPatch,
  getStudentTransactionHistory,
} from "services/entity-apis";
import {
  studentKeys,
  studentExcelKeys,
  studentHeaderKeys,
  studentRowsKeys,
  studentUniqueKey,
  studentModalName,
  transactionModalName,
  nameRegx,
  emailRegx,
  mobileRegx,
} from "config/entity.config";
import {
  formatDate,
  validateForm,
  convertEntityExcelData,
} from "services/shared-logic.service";
import TransactionModal from "components/transaction-modal/transaction-modal";
import { downloadPaymentReceipt } from "services/payment.service";

export default class Student extends React.Component {
  minJoiningDate;
  maxJoiningDate;
  minBirthDate;
  maxBirthDate;
  minAge = 5;
  maxAge = 40;
  statusSortOrder = ["active", "inactive", null];
  bloodGroupList = ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'];

  jourseySize = ['XS-26', 'S-28', 'M-30', 'L-32', 'XL-34', 'XS-36', 'S-38', 'M-40', 'L-42', 'XL-44'];
  today = +new Date() / 1000;

  constructor(props) {
    super(props);
    this.setMinMaxJoiningDate();
    this.setMinMaxBirthDate();
    this.state = {
      organizationData: null,
      batchList: [],
      studentList: [],
      productsData: [],
      batchFilteredStudentList: [],
      showingStudentList: [],
      studentSearchValue: '',
      loading: true,
      selectedBatch: null,
      showActivePlayers: 'true',
      studentForm: {
        mode: "add",
        data: this.initializeStudentFormData(),
      },
      errors: {
        firstName: "",
        lastName: "",
        parent_name: "",
        email: "",
        mobile: "",
        alternate_contact: ""
      },
      keyValChange: this.resetChangedKeys(),
      transactionSelectedStudent: null,
    };
  }

  setMinMaxJoiningDate() {
    var d = new Date();
    d.setDate(d.getDate() - 30);
    this.minJoiningDate = formatDate(new Date(2012, 0, 1));

    this.maxJoiningDate = formatDate(new Date());
  }

  setMinMaxBirthDate() {
    var d1 = new Date();
    d1.setDate(d1.getDate() - 40 * 365);
    this.minBirthDate = formatDate(d1);

    var d2 = new Date();
    d2.setDate(d2.getDate() - 3 * 365);
    this.maxBirthDate = formatDate(d2);
  }

  initializeStudentFormData(student = {}) {
    return {
      [studentKeys.studentId]: student[studentKeys.studentId] || null,
      [studentKeys.photo]: React.createRef(),
      [studentKeys.firstName]: student[studentKeys.firstName] || "",
      [studentKeys.lastName]: student[studentKeys.lastName] || "",
      [studentKeys.parentName]: student[studentKeys.parentName] || "",
      [studentKeys.dob]: student[studentKeys.dob] || "",
      [studentKeys.email]: student[studentKeys.email] || "",
      [studentKeys.address]: student[studentKeys.address] || "",
      [studentKeys.mobile]: student[studentKeys.mobile] || "",
      [studentKeys.alternate_contact]: student[studentKeys.alternate_contact] || "",
      [studentKeys.preferredFoot]: student[studentKeys.preferredFoot] || "",
      [studentKeys.batches]: student[studentKeys.batches] || [],
      [studentKeys.joiningDate]: student[studentKeys.joiningDate] || "",
      [studentKeys.joiningMethod]:
        student[studentKeys.joiningMethod] || "online",
      [studentKeys.status]: student[studentKeys.status],
      [studentKeys.bloodGroup]: student[studentKeys.bloodGroup] || "",
      [studentKeys.jourseySize]: student[studentKeys.jourseySize] || ""
    };
  }

  resetChangedKeys() {
    return {
      [studentKeys.firstName]: false,
      [studentKeys.lastName]: false,
      [studentKeys.parentName]: false,
      [studentKeys.dob]: false,
      [studentKeys.email]: false,
      [studentKeys.address]: false,
      [studentKeys.mobile]: false,
      [studentKeys.alternate_contact]: false,
      [studentKeys.preferredFoot]: false,
      [studentKeys.batches]: false,
      [studentKeys.joiningDate]: false,
      [studentKeys.joiningMethod]: false,
      [studentKeys.bloodGroup]: false,
      [studentKeys.jourseySize]: false
    };
  }

  componentDidMount() {
    this.getBatchData();
    this.getStudentData();
    this.getProductsData();
    this.getOrganizationData();
  }

  async getOrganizationData() {
    const data = await getEntityData('organizations').catch((err) => {
      console.log(err);
    })

    if (data) {
      await this.setState({
        organizationData: data.data || {},
      })
    }
  }

  async getStudentData() {
    const data = await getEntityData("students").catch((err) => {
      this.setState({ loading: false });
      console.log(err);
    });

    if (data) {

      const finalResult = data.data
        ? data.data.sort((a, b) => {
          return (
            this.statusSortOrder.indexOf(a[studentKeys.status]) -
            this.statusSortOrder.indexOf(b[studentKeys.status])
          );
        }).map(item => {
          item['bgColor'] = item[studentKeys.status] === 'active' ? 'bg-success-light' : (item[studentKeys.status] === 'inactive' ? 'bg-danger-light' : 'bg-warning-light');
          return item;
        })
        : [];
      await this.setState({
        loading: false,
        studentList: JSON.parse(JSON.stringify(finalResult))
      });
      this.onBatchFilter(this.state.selectedBatch || 'all');
    }
  }

  async getBatchData() {
    const batchData = await getEntityData("batches").catch((err) => {
      console.log(err);
    });
    if (batchData) {
      this.setState({
        batchList: batchData.data || [],
      });
    }
  }

  async getProductsData() {
    const productsData = await getEntityData('products').catch((err) => {
      console.log(err);
    });
    if (productsData) {
      this.setState({
        productsData: productsData.data
      })
    }
  }

  handleChange(key, event) {
    this.validateFormData(event.target.id, event.target.value);
    let value =
      key !== studentKeys.batches
        ? event.target.value
        : Array.from(event.target.selectedOptions, (option) => option.value);

    this.setState({
      studentForm: {
        mode: this.state.studentForm.mode,
        data: { ...this.state.studentForm.data, [key]: value },
      },
      keyValChange: { ...this.state.keyValChange, [key]: true },
    });
  }

  validateFormData(id, value) {
    switch (id) {
      case studentKeys.firstName:
        this.setState({
          errors: {
            ...this.state.errors,
            firstName: nameRegx.test(value)
              ? ""
              : "Please Enter Valid First Name",
          },
        });
        break;
      case studentKeys.lastName:
        this.setState({
          errors: {
            ...this.state.errors,
            lastName: nameRegx.test(value)
              ? ""
              : "Please Enter Valid Last Name",
          },
        });
        break;
      case studentKeys.parentName:
        this.setState({
          errors: {
            ...this.state.errors,
            parent_name: nameRegx.test(value) ? "" : "Please Enter Valid Name",
          },
        });
        break;
      case studentKeys.email:
        this.setState({
          errors: {
            ...this.state.errors,
            email: emailRegx.test(value) ? "" : "Please Enter Valid Email ID",
          },
        });
        break;
      case studentKeys.mobile:
        this.setState({
          errors: {
            ...this.state.errors,
            mobile: mobileRegx.test(value)
              ? ""
              : "Please Enter Valid Mobile Number",
          },
        });
        break;
      case studentKeys.alternate_contact:
        this.setState({
          errors: {
            ...this.state.errors,
            alternate_contact: mobileRegx.test(value)
              ? ""
              : "Please Enter Valid Alternate Contact",
          },
        });
        break;
      default:
        console.log("Validation Not Required!");
    }
  }

  async onStudentFormSubmit(e) {
    e.preventDefault();

    if (!validateForm(this.state.errors)) {
      return;
    }

    const reqData = { ...this.state.studentForm.data };
    for (const [key, value] of Object.entries(reqData)) {
      if (typeof value === "string") {
        reqData[key] = value.trim();
      }
    }
    delete reqData[studentKeys.photo];

    if (this.state.studentForm.mode === "add") {
      delete reqData[studentKeys.studentId];
      reqData[studentKeys.status] = "active";
      const studentData = await addEntityData("students", reqData).catch(
        (err) => {
          console.log(err);
        }
      );
      if (studentData) {
        this.patchStudentPhoto(studentData.data[studentKeys.studentId]);
      }
    } else if (this.state.studentForm.mode === "edit") {
      const patchableData = {};
      Object.keys(reqData).forEach((item) => {
        if (this.state.keyValChange[item]) {
          patchableData[item] = reqData[item];
        }
      });
      await patchEntity(
        "students",
        reqData[studentKeys.studentId],
        patchableData
      ).catch((err) => {
        console.log(err);
      });
      this.patchStudentPhoto(reqData[studentKeys.studentId]);
    }
  }

  async patchStudentPhoto(studentId) {
    if (this.state.studentForm.data[studentKeys.photo].current.files[0]) {
      const formData = new FormData();
      formData.append(
        "photo",
        this.state.studentForm.data[studentKeys.photo].current.files[0]
      );
      const photoPatchData = await entityPhotoPatch(
        "students",
        studentId,
        formData
      ).catch((err) => {
        console.log(err);
      });
      if (photoPatchData) {
        $(`#${studentModalName}`).modal("hide");
        this.getStudentData();
      }
    } else {
      $(`#${studentModalName}`).modal("hide");
      this.getStudentData();
    }
  }

  async openAddStudentModal() {
    await this.setState({
      studentForm: {
        mode: "add",
        data: this.initializeStudentFormData(),
      },
    });
    $(`#${studentModalName}`).modal("show");
  }

  async deleteStudent(studentId) {
    let confirm = window.confirm("Are you sure want to delete this entry!");
    if (confirm) {
      const resData = await deleteEntity("students", studentId).catch((res) => {
        console.log(res);
      });

      if (resData) {
        this.getStudentData();
      }
    }
  }

  async editStudent(studentId) {
    // get student data using student id
    const reqStu = this.state.studentList.find(
      (item) => item[studentUniqueKey] === studentId
    );
    if (reqStu) {
      // initialize form with prefill data
      await this.setState({
        studentForm: {
          mode: "edit",
          data: this.initializeStudentFormData(reqStu),
        },
        keyValChange: this.resetChangedKeys(),
      });
      $(`#${studentModalName}`).modal("show");
    }
  }

  async handleBulkInsert(excelData) {
    await this.setState({ loading: true });
    const studentData = await addEntityData(
      "students",
      convertEntityExcelData(excelData)
    ).catch((err) => {
      console.log(err);
    });
    if (studentData) {
      this.getStudentData();
    }
  }

  async addTransaction(studentId) {
    // get student data using student id
    const reqStu = this.state.studentList.find(
      (item) => item[studentUniqueKey] === studentId
    );
    if (reqStu) {
      await this.setState({
        transactionSelectedStudent: reqStu,
      });
      this.openChild();
      $(`#${transactionModalName}`).modal("show");
    }
  }

  async downloadTransaction(studentId) {
    const reqStu = this.state.studentList.find(
      (item) => item[studentUniqueKey] === studentId
    );
    if (reqStu) {
      const transacData = await getStudentTransactionHistory(studentId).catch(
        (err) => {
          console.log(err);
        }
      );

      if (transacData?.data?.length) {
        downloadPaymentReceipt(reqStu, transacData.data?.[0], this.state.organizationData);
      }
    }
  }

  async onBatchFilter(batchCode) {
    await this.setState({
      selectedBatch: batchCode === "all" ? null : batchCode,
      batchFilteredStudentList:
        batchCode === "all"
          ? this.state.studentList
          : this.state.studentList.filter(
            (stu) =>
              stu[studentKeys.batches] &&
              stu[studentKeys.batches].find((item) => item === batchCode)
          ),
    });
    this.setShowingStudentList();
  }

  async onSearchPlayer(event) {
    await this.setState({
      studentSearchValue: event.target.value
    });
    this.setShowingStudentList();
  }

  async onActivePlayerToggle(event) {
    await this.setState({
      showActivePlayers: this.state.showActivePlayers === 'true' ? 'false' : 'true'
    })
    this.setShowingStudentList();
  }

  async setShowingStudentList() {
    await this.setState({
      showingStudentList: this.state.batchFilteredStudentList.filter((stu) => {
        return this.state.studentSearchValue ? (
          (
            (stu[studentKeys.firstName] || "").trim() +
            " " +
            (stu[studentKeys.lastName] || "").trim()
          )
            .toLowerCase()
            .includes(this.state.studentSearchValue.toLowerCase()) &&
          (this.state.showActivePlayers === 'true' ? (stu.status === 'active' || stu.status === 'renewal_pending') : (!stu.status || stu.status === 'inactive'))
        ) : (this.state.showActivePlayers === 'true' ? (stu.status === 'active' || stu.status === 'renewal_pending') : (!stu.status || stu.status === 'inactive'))
      })
    })
  }

  render() {
    const { errors, studentForm, batchList, selectedBatch } = this.state;
    return (
      <>
        <Entity
          batches={batchList}
          selectedBatch={selectedBatch}
          entityRowsKeys={studentRowsKeys}
          entityHeaderKeys={studentHeaderKeys}
          uniqueKey={studentUniqueKey}
          entityName="Players"
          modalName={studentModalName}
          entityDataList={this.state.showingStudentList}
          entityExcelKeys={studentExcelKeys}
          loading={this.state.loading}
          addBtnDisabled={batchList.length > 0 ? false : true}
          addEntity={this.openAddStudentModal.bind(this)}
          deleteEntity={this.deleteStudent.bind(this)}
          editEntity={this.editStudent.bind(this)}
          handleBulkInsert={this.handleBulkInsert.bind(this)}
          handleAddTransaction={this.addTransaction.bind(this)}
          handleDownloadTransaction={this.downloadTransaction.bind(this)}
          onBatchFilter={this.onBatchFilter.bind(this)}
          hasSearch="true"
          hasActiveToggle="true"
          showActivePlayers={this.state.showActivePlayers}
          onSearch={this.onSearchPlayer.bind(this)}
          onActivePlayerToggle={this.onActivePlayerToggle.bind(this)}
          editAction={true}
        />

        <div
          className="modal fade"
          id={studentModalName}
          tabIndex="-1"
          aria-labelledby="StudentModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="StudentModalLabel">
                  Add Player Details
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={this.onStudentFormSubmit.bind(this)}>
                  <div style={{ marginBottom: "16px" }}>
                    <div style={{ marginBottom: "8px" }}> Photo </div>
                    <input
                      type="file"
                      accept=".png, .jpg"
                      className="form-control-file"
                      id="studentPhoto"
                      ref={studentForm.data[studentKeys.photo]}
                    ></input>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="firstname">First Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstname"
                        placeholder="First Name"
                        maxLength="15"
                        required
                        value={studentForm.data[studentKeys.firstName]}
                        onChange={this.handleChange.bind(
                          this,
                          studentKeys.firstName
                        )}
                      />
                      {errors.firstName.length > 0 && (
                        <span className="error">{errors.firstName}</span>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="lastname">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastname"
                        placeholder="Last Name"
                        maxLength="15"
                        value={studentForm.data[studentKeys.lastName]}
                        onChange={this.handleChange.bind(
                          this,
                          studentKeys.lastName
                        )}
                      />
                      {errors.lastName.length > 0 && (
                        <span className="error">{errors.lastName}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="parentname">
                        Parent's/ Gaurdian's Name*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="parentname"
                        placeholder="Parent's name"
                        maxLength="15"
                        value={studentForm.data[studentKeys.parentName]}
                        onChange={this.handleChange.bind(
                          this,
                          studentKeys.parentName
                        )}
                      />
                      {errors.parent_name.length > 0 && (
                        <span className="error">{errors.parent_name}</span>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="dob">Birth Date*</label>
                      <input
                        type="date"
                        className="form-control"
                        id="dob"
                        placeholder="DoB"
                        required
                        min={this.minBirthDate}
                        max={this.maxBirthDate}
                        value={studentForm.data[studentKeys.dob]}
                        onChange={this.handleChange.bind(this, studentKeys.dob)}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label htmlFor="email">Email *</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        required
                        value={studentForm.data[studentKeys.email]}
                        onChange={this.handleChange.bind(
                          this,
                          studentKeys.email
                        )}
                      />
                      {errors.email.length > 0 && (
                        <span className="error">{errors.email}</span>
                      )}
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="mobile">Mobile *</label>
                      <input
                        type="text"
                        className="form-control"
                        id="mobile"
                        placeholder="10 digit Mobile No."
                        required
                        value={studentForm.data[studentKeys.mobile]}
                        onChange={this.handleChange.bind(
                          this,
                          studentKeys.mobile
                        )}
                      />
                      {errors.mobile.length > 0 && (
                        <span className="error">{errors.mobile}</span>
                      )}
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="alternate_contact">Alternate Contact</label>
                      <input
                        type="text"
                        className="form-control"
                        id="alternate_contact"
                        placeholder="Alternate Contact No."
                        value={studentForm.data[studentKeys.alternate_contact]}
                        onChange={this.handleChange.bind(
                          this,
                          studentKeys.alternate_contact
                        )}
                      />
                      {errors.alternate_contact.length > 0 && (
                        <span className="error">{errors.alternate_contact}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="bloodgroup"> Blood Group*</label>
                      <select id="bloodgroup" className="form-control"
                        value={studentForm.data[studentKeys.bloodGroup]} onChange={this.handleChange.bind(this, studentKeys.bloodGroup)}
                      >
                        <option value=""> Select </option>
                        {
                          this.bloodGroupList.map((item) => {
                            return <option value={item} key={item}> {item} </option>
                          })
                        }

                      </select>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="JourseySize"> Joursey Size</label>
                      <select id="JourseySize" className="form-control"
                        value={studentForm.data[studentKeys.jourseySize]} onChange={this.handleChange.bind(this, studentKeys.jourseySize)}
                      >
                        <option value=""> Select </option>
                        {
                          this.jourseySize.map((item) => {
                            return <option value={item} key={item}> {item} </option>
                          })
                        }

                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="preferredfoot"> Preferred Foot</label>
                      <select
                        id="preferredfoot"
                        className="form-control"
                        value={studentForm.data[studentKeys.preferredFoot]}
                        onChange={this.handleChange.bind(
                          this,
                          studentKeys.preferredFoot
                        )}
                      >
                        <option value=""> Select </option>
                        <option value="right"> Right </option>
                        <option value="left"> Left </option>
                      </select>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="batchCode"> Batch Code *</label>
                      <select
                        id="batchCode"
                        multiple
                        className="form-control selectpicker"
                        data-live-search="true"
                        required
                        value={studentForm.data[studentKeys.batches]}
                        onChange={this.handleChange.bind(
                          this,
                          studentKeys.batches
                        )}
                      >
                        {batchList.map((item) => {
                          return (
                            <option value={item.code} key={item.code}>
                              {" "}
                              {item.code}{" "}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="address">Address *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="address"
                      placeholder="1234 Main St"
                      maxLength="50"
                      required
                      value={studentForm.data[studentKeys.address]}
                      onChange={this.handleChange.bind(
                        this,
                        studentKeys.address
                      )}
                    />
                  </div>
                  {this.state.studentForm.mode === "edit" && (
                    <div className="form-group">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="statusRadio"
                          id="statusRadio"
                          checked={
                            studentForm.data[studentKeys.status] === "active"
                          }
                          value={
                            studentForm.data[studentKeys.status] === "active"
                              ? "inactive"
                              : "active"
                          }
                          onChange={this.handleChange.bind(
                            this,
                            studentKeys.status
                          )}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="statusRadio"
                        >
                          Is Active?
                        </label>
                      </div>
                    </div>
                  )}
                  <div className="form-row">
                    {this.state.studentForm.mode === "add" && (
                      <div className="form-group col-md-6">
                        <label htmlFor="joiningdate">Joining Date *</label>
                        <input
                          type="date"
                          className="form-control"
                          id="joiningdate"
                          placeholder="Joining Date"
                          required
                          min={this.minJoiningDate}
                          max={this.maxJoiningDate}
                          value={studentForm.data[studentKeys.joiningDate]}
                          onChange={this.handleChange.bind(
                            this,
                            studentKeys.joiningDate
                          )}
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    <button className="btn btn-success w-100" type="submit">
                      {" "}
                      Submit{" "}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <TransactionModal
          student={this.state.transactionSelectedStudent}
          products={this.state.productsData}
          setOpen={(open) => (this.openChild = open)}
        />
      </>
    );
  }
}
