import { Switch, Route, Redirect } from 'react-router-dom';
import React from 'react';
import Header from '../header/header';
import AuthError from '../shared/authentication-error';
import Four04Error from 'components/shared/four-0-4-error';
import { appRoutes } from 'config/app.config';

export default class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: null
        }
    }

    render() {
        let mainDiv;
        if (!this.props.token) {
            mainDiv = <AuthError />
        } else {
            const allowedRoutes = JSON.parse(sessionStorage.getItem("access")) || [];
            mainDiv =
                <Switch>
                    {
                        allowedRoutes.map((item) => {
                            const Component = appRoutes[item]?.component;
                            return (
                                <Route exact key={item} path={item}>
                                    <Component token={this.props.token} />
                                </Route>
                            )
                        })
                    }
                    <Redirect exact from="/" to={allowedRoutes?.[0]} />
                    <Route>
                        <Four04Error />
                    </Route>
                </Switch>
        }

        return (
            <>
                <Header token={this.props.token} username={this.props.username} logout={this.props.logout} />
                {mainDiv}
            </>
        )
    }
}