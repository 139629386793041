import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getBatchData, getAllEntitiesData, getBatchWiseData, submitStudentAttendance, fetchStudentAttendance, updateStudentAttendance } from "services/entity-apis";
import { weekDays, studentKeys, staffKeys, batchKeys, studentAttendanceKeys } from 'config/entity.config';
import { convertEpochToLocalTime } from 'services/shared-logic.service';
import ErrorMsg from 'components/shared/error-msg';
import './attendance-tracking.scss';

const START_BUFFER_TIME = 30; // in minutes

export default class Tracking extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      staffData: {
        [staffKeys.batches]: []
      },
      studentList: [],
      batchDetails: [],
      selectedBatch: null,
      selectedBatchTimeIdx: 0,
      mode: 'create',
      studentPrevData: [],
      latestUpdateTime: null,
      presentCount: null
    }
  }

  componentDidMount() {
    this.getAllBatches();
  }

  async getAllBatches() {
    const batchData = await getAllEntitiesData('batches').catch(err => {
      console.log(err);
    })

    if (batchData?.data) {
      await this.setState({
        staffData: {
          [staffKeys.batches]: batchData.data.map(item => item.code)
        },
      })
      if (sessionStorage.getItem("selectedBatch")) {
        this.setSelectedBatch(sessionStorage.getItem("selectedBatch"));
      }
    }
  }

  async getBatchDetails(batchCode) {
    const batchData = await getBatchData(batchCode).catch((err) => {
      console.log(err);
    })

    if (batchData) {
      const ongoingBatch = this.checkIfOngoingBatch(batchData.data);
      if (ongoingBatch.length) {
        this.setState({
          batchDetails: ongoingBatch,
          selectedBatchTimeIdx: 0
        })
        Promise.all([
          this.getStudentListBatchWise(),
          this.getAttendanceTelemetry(ongoingBatch[0].strtMin, ongoingBatch[0].endMin)
        ]).then((res) => {
          if (this.state.mode === 'edit') {
            const prevData = this.state.studentPrevData;
            this.setState({
              studentList: this.state.studentList.map((item) => {
                const student = prevData.find((stu) => stu[studentKeys.studentId] === item[studentKeys.studentId]);
                item['attend'] = student ? student[studentAttendanceKeys.status] : 'ABSENT';
                return item;
              })
            })
          } else {
            this.setState({
              studentList: this.state.studentList.map((item) => { item['attend'] = 'ABSENT'; return item; })
            })
          }
        })
      } else {
        this.setState({
          batchDetails: []
        })
      }
    }
  }

  async getAttendanceTelemetry(strtMin, endMin) {
    const currDayEpoch = Math.floor((new Date()).setHours(0, 0, 0, 0) / 1000);
    const reqObj = {
      from: currDayEpoch + (strtMin * 60),
      to: currDayEpoch + (endMin * 60),
      batch: this.state.selectedBatch
    }
    const attenData = await fetchStudentAttendance(reqObj).catch((err) => {
      console.log(err);
    })

    if (attenData && attenData.data && attenData.data.length) {
      await this.setState({
        studentPrevData: attenData.data,
        mode: 'edit',
        latestUpdateTime: attenData.data[0][studentAttendanceKeys.date],
        presentCount: attenData.data.filter(item => item[studentAttendanceKeys.status] === 'PRESENT').length
      })
    } else {
      await this.setState({
        studentPrevData: [],
        mode: 'create',
        latestUpdateTime: null,
        presentCount: null
      })
    }
  }

  async getStudentListBatchWise() {
    const stuData = await getBatchWiseData('students', this.state.selectedBatch).catch((err) => {
      console.log(err);
    })

    if (stuData && stuData.data && stuData.data.length) {
      await this.setState({
        studentList: stuData.data || []
      })
    } else {
      await this.setState({
        studentList: []
      })
    }
  }

  changeStudentAttend(stu, status) {
    const studentList = this.state.studentList;
    studentList.map(item => {
      if (item[studentKeys.studentId] === stu[studentKeys.studentId]) {
        item.attend = status;
      }
      return item;
    })
    this.setState({
      studentList
    })
  }

  setSelectedBatch(batch) {
    this.setState({
      selectedBatch: batch,
      latestUpdateTime: null
    })
    sessionStorage.setItem("selectedBatch", batch);
    this.getBatchDetails(batch);
  }


  // to check whether specific batch is ongoing or not
  checkIfOngoingBatch(batchData) {
    const currDate = new Date();
    const currDay = weekDays[currDate.getDay()];
    const currMin = (currDate.getHours() * 60) + (currDate.getMinutes())
    const dayTime = batchData[batchKeys.dayTime].filter((item) => item[batchKeys.day] === currDay)
    const reqData = [];
    if (dayTime.length) {
      dayTime.forEach((item) => {
        const strtArr = item[batchKeys.startTime].split(":");
        const strtMin = (+strtArr[0] * 60) + +strtArr[1] - START_BUFFER_TIME;

        const endMin = 1440; // max limit of day

        if ((strtMin < endMin) && (strtMin <= currMin) && (currMin <= endMin)) {
          reqData.push({
            [batchKeys.code]: batchData[batchKeys.code], [batchKeys.day]: item[batchKeys.day],
            [batchKeys.startTime]: item[batchKeys.startTime], [batchKeys.endTime]: item[batchKeys.endTime],
            strtMin, endMin
          });
        }
      })
    }
    return reqData;
  }

  onClickSubmit() {
    if (this.state.mode === 'create') {
      this.submitAttendance();
    } else {
      this.editAttendance();
    }
  }

  async submitAttendance() {
    const currTime = Math.floor(Date.now() / 1000);
    const reqArr = this.state.studentList.filter(item => item.status !== 'inactive').map((item) => {
      return {
        [studentAttendanceKeys.studentId]: item[studentKeys.studentId],
        [studentAttendanceKeys.batch]: this.state.selectedBatch,
        [studentAttendanceKeys.date]: currTime,
        [studentAttendanceKeys.status]: item['attend'].toUpperCase()
      }
    })
    const res = await submitStudentAttendance(reqArr).catch((err) => {
      console.log(err);
    })

    if (res) {
      window.location.reload();
    }
  }

  async editAttendance() {
    const currTime = Math.floor(Date.now() / 1000);
    const reqObj = {
      old_date: +this.state.latestUpdateTime,
      new_date: currTime,
      data: this.state.studentList.filter(item => item.status !== 'inactive').map((item) => {
        return {
          [studentAttendanceKeys.studentId]: item[studentKeys.studentId],
          [studentAttendanceKeys.status]: item['attend'].toUpperCase()
        }
      })
    }
    const res = await updateStudentAttendance(reqObj).catch((err) => {
      console.log(err);
    })

    if (res) {
      window.location.reload();
    }
  }

  removeSelectedBatch() {
    this.setState({ selectedBatch: null });
    sessionStorage.removeItem("selectedBatch");
  }

  render() {
    const { selectedBatch, staffData, studentList } = this.state;
    return (
      <>
        {(!selectedBatch) && <div className="container mt-2">
          <div className="batch-cards row">
            {
              staffData?.[staffKeys.batches]?.map((batch, idx) => {
                return (
                  <div className="col-6 col-md-4" key={batch}>
                    <div className="batch-card">
                      <div className="action-btns d-flex justify-content-around w-100 ">
                        <button className="action-btns" onClick={this.setSelectedBatch.bind(this, batch)} key={batch}>{batch}</button>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        }

        {selectedBatch && <div className="container mt-2">
          <div className="top-head d-flex align-items-center justify-content-between">
            <div>
              <button className="btn btn-link" onClick={this.removeSelectedBatch.bind(this)}>
                <span className="mr-3"><FontAwesomeIcon icon={["fas", "arrow-left"]} /></span>
                Back
              </button>
              <span className="badge badge-info mb-0" title="School Code">{selectedBatch}</span>
            </div>
            {
              this.state.latestUpdateTime ?
                <div className="d-flex flex-wrap align-items-center update-txt">
                  <div className="text-primary">
                    Last Updated on {convertEpochToLocalTime(this.state.latestUpdateTime * 1000)}
                  </div>
                  <div className="ml-4">
                    Present - ({this.state.presentCount}/{studentList.length})
                  </div>
                </div> :
                <div className='text-muted'>
                  Attendance not submitted
                </div>
            }
            <div className="d-flex">
              {!!this.state.batchDetails.length &&
                <div className="batch-time d-flex align-items-center mr-2"> {this.state.batchDetails[0][batchKeys.day]} &nbsp;
                  <DropdownButton title={`${this.state.batchDetails[this.state.selectedBatchTimeIdx][batchKeys.startTime]} - ${this.state.batchDetails[this.state.selectedBatchTimeIdx][batchKeys.endTime]}`}
                    size="sm" variant="secondary">
                    {
                      this.state.batchDetails.map((batch, idx) => {
                        return (
                          <Dropdown.Item key={`${batch[batchKeys.startTime]} - ${batch[batchKeys.endTime]}`} onClick={() => { this.setState({ selectedBatchTimeIdx: idx }) }}>
                            {`${batch[batchKeys.startTime]} - ${batch[batchKeys.endTime]}`}
                          </Dropdown.Item>
                        )
                      })
                    }
                  </DropdownButton>
                </div>
              }
              {!!(this.state.selectedBatch && this.state.batchDetails.length && studentList.length) &&
                <button className="btn btn-sm btn-success" onClick={this.onClickSubmit.bind(this)}> Submit Attendance</button>
              }
            </div>
          </div>
          {!!(this.state.selectedBatch && this.state.batchDetails.length) &&
            <div className="student-cards row">
              {
                studentList.map((stu) => {
                  return (
                    <div className="col-6 col-md-3" key={stu[studentKeys.studentId]}>
                      <div className={`student-card ${stu.status === 'renewal_pending' ? 'bg-warning-light' : (stu.status === 'inactive' ? 'disabled' : '')}`}>
                        <div className="stu-img">
                          {/* <img src="pic.jpg"></img> */}
                          <FontAwesomeIcon icon={['fas', 'user']} />
                        </div>
                        <div className="stu-name">
                          {stu[studentKeys.firstName] + ' ' + stu[studentKeys.lastName]}
                        </div>
                        <div className="stu-id">
                          {stu[studentKeys.studentId]}
                        </div>
                        <div className="action-btns d-flex justify-content-around w-100">
                          <button className={`btn absent ${stu.attend === 'ABSENT' ? 'active' : ''}`}
                            onClick={this.changeStudentAttend.bind(this, stu, 'ABSENT')}> A </button>
                          <button className={`btn present ${stu.attend === 'PRESENT' ? 'active' : ''}`}
                            onClick={this.changeStudentAttend.bind(this, stu, 'PRESENT')}> P </button>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>}
          {!!(this.state.selectedBatch && !this.state.batchDetails.length) &&
            <div style={{ marginTop: '20px' }}>
              <ErrorMsg msg="Batch not ongoing !" />
            </div>
          }
        </div>}
      </>
    )
  }
}
