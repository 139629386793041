import React from "react";
import $ from "jquery";
import "./login.css";
import { login } from "services/login-api";

function ErrorMsg(props) {
  if (props.errorStatus === "wrong") {
    return (
      <div className="error-msg"> *Username or password is incorrect.</div>
    );
  } else if (props.errorStatus === "unsure") {
    return (
      <div className="error-msg"> *Something went wrong. Please try again.</div>
    );
  }
  return null;
}

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orgId: null,
      id: null,
      // username: null,
      password: null,
      errorStatus: null,
    };
  }

  async handleSubmit(e) {
    e.preventDefault();
    sessionStorage.setItem("org_id", this.state.orgId);
    const data = await login({
      id: this.state.id,
      // username: this.state.username,
      password: this.state.password,
    }).catch((error) => {
      const errRes = error.response || {};
      if (errRes.status === 401) {
        this.setState({ errorStatus: "wrong" });
      } else {
        this.setState({ errorStatus: "unsure" });
      }
    });

    if (data && data.data && data.data.token) {
      this.storeDataSS(data);
      this.props.setToken(data.data.token, true);
      $("#loginModal").modal("hide");
    }
  }

  // store data in session storage
  storeDataSS(data) {
    sessionStorage.setItem("token", data.data.token);
    sessionStorage.setItem("user", data.data.name);
    sessionStorage.setItem("userId", data.data.user);
    sessionStorage.setItem("access", data.data.access);
  }

  render() {
    return (
      <div
        className="modal fade"
        id="loginModal"
        tabIndex="-1"
        aria-labelledby="loginModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="loginModalLabel">
                Login
              </h5>
            </div>
            <div className="modal-body">
              <form onSubmit={this.handleSubmit.bind(this)}>
                <div className="input-group flex-nowrap">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="org-addon-wrapping">
                      $
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Org ID"
                    aria-label="Organization ID"
                    aria-describedby="org-addon-wrapping"
                    onChange={(e) => this.setState({ orgId: e.target.value })}
                  />
                </div>
                <div className="input-group flex-nowrap my-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="id-addon-wrapping">
                      @
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="User id"
                    aria-label="Username"
                    maxLength="4"
                    pattern="[0-9]{3}"
                    aria-describedby="id-addon-wrapping"
                    onChange={(e) => this.setState({ id: e.target.value })}
                  />
                </div>
                <div className="input-group flex-nowrap my-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="pass-addon-wrapping">
                      *
                    </span>
                  </div>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    aria-label="Password"
                    aria-describedby="pass-addon-wrapping"
                    maxLength="30"
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                  />
                </div>
                <ErrorMsg errorStatus={this.state.errorStatus} />
                <div>
                  <button className="btn btn-success w-100" type="submit">
                    {" "}
                    Submit{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
