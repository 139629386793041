import React from "react";
import $ from 'jquery';
import './roles.css'
import Entity from "components/entities/entity/entity";
import { getEntityData, addEntityData, patchEntity } from 'services/entity-apis';
import {
    rolesRowsKeys, rolesHeaderKeys, rolesKeys,rolesModalName,rolesUniqueKey
} from 'config/entity.config';
import { validateForm } from 'services/shared-logic.service';
import { appRoutes } from 'config/app.config';

export default class Roles extends React.Component {

    navList = Object.keys(appRoutes);

    constructor(props) {
        super(props);
        this.state = {
            rolesList: [],
            loading: true,
            rolesForm: {
                mode: 'add',
                data: this.initializeRolesFormData()
            },
            errors: {
            },
            keyValChange: this.resetChangedKeys()
        }
    }

    initializeRolesFormData(roles = {}) {
        return {
            [rolesKeys.category]: roles[rolesKeys.category] || '',
            [rolesKeys.accessLinks]: roles[rolesKeys.accessLinks] || []
        }
    }

    resetChangedKeys() {
        return {
            [rolesKeys.category]: false,
            [rolesKeys.accessLinks]: false,
        }
    }

    componentDidMount() {
        this.getRolesData();
    }

    async getRolesData() {
        await this.setState({ loading: true })

        const data = await getEntityData('roles').catch((err) => {
            this.setState({ loading: false })
            console.log(err);
        })

        if (data) {
            await this.setState({
                loading: false,
                rolesList: data.data || []
            })
        }
    }

    handleChange(key, event) {
        // this.validateFormData(event.target.id, event.target.value);
        // const value = event.target.value;
        const value = key !== rolesKeys.accessLinks ? event.target.value :
        Array.from(event.target.selectedOptions, option => option.value);

        this.setState({
            rolesForm: {
                mode: this.state.rolesForm.mode,
                data: { ...this.state.rolesForm.data, [key]: value }
            },
            keyValChange: { ...this.state.keyValChange, [key]: true }
        })
    }

    validateFormData(id, value) {
        switch (id) {
            default:
                console.log('Validation Not Required!')
        }
    }

    async onRolesFormSubmit(e) {
        e.preventDefault();

        if (!validateForm(this.state.errors)) {
            return;
        }

        const reqData = { ...this.state.rolesForm.data };
        for (const [key, value] of Object.entries(reqData)) {
            if (typeof value === 'string') {
                reqData[key] = value.trim();
            }
        }
        // delete reqData.dependentEntity;

        let rolesData;
        if (this.state.rolesForm.mode === 'add') {
            rolesData = await addEntityData('roles', reqData).catch((err) => {
                console.log(err);
            })
        } else if (this.state.rolesForm.mode === 'edit') {
            const patchableData = {};
            Object.keys(reqData).forEach((item) => {
                if (this.state.keyValChange[item]) {
                    patchableData[item] = reqData[item];
                }
            })
            rolesData = await patchEntity('roles', reqData[rolesKeys.category], patchableData).catch((err) => {
                console.log(err);
            })
        }
        if (rolesData) {
            $(`#${rolesModalName}`).modal('hide');
            this.getRolesData();
        }
    }

    async openAddRolesModal() {
        await this.setState({
            rolesForm: {
                mode: 'add',
                data: this.initializeRolesFormData()
            }
        })
        $(`#${rolesModalName}`).modal('show');
    }

    async editRoles(category) {
        const reqBat = this.state.rolesList.find((item) => item[rolesUniqueKey] === category);
        if (reqBat) {
            // initialize form with prefill data
            console.log("edit roles",reqBat)
            await this.setState({
                rolesForm: {
                    mode: 'edit',
                    data: this.initializeRolesFormData(reqBat)
                },
                keyValChange: this.resetChangedKeys()
            });
            $(`#${rolesModalName}`).modal('show');
        }
    }

    render() {
        const { rolesList, rolesForm } = this.state;
        return (
            <>
                <Entity
                    entityRowsKeys={rolesRowsKeys} entityHeaderKeys={rolesHeaderKeys}
                    uniqueKey={rolesUniqueKey} entityName='Roles'
                    modalName={rolesModalName} entityDataList={rolesList}
                    loading={this.state.loading}
                    addEntity={this.openAddRolesModal.bind(this)}
                    editEntity={this.editRoles.bind(this)}
                    editAction={true}
                />

                <div className="modal fade" id={rolesModalName} tabIndex="-1" aria-labelledby="RoleModalLabel" aria-hidden="true">
                    <div className="modal-dialog min-width">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="RoleModalLabel">Add Roles Details</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.onRolesFormSubmit.bind(this)}>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label htmlFor="category">Category*</label>
                                            <input type="text" className="form-control" id="category" placeholder="Category" maxLength="30" required
                                                value={rolesForm.data[rolesKeys.category]} onChange={this.handleChange.bind(this, rolesKeys.category)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label htmlFor="accessLinks"> Access Links*</label>
                                            <select id="accessLinks"
                                                className="form-control selectpicker"
                                                multiple
                                                data-live-search="true"
                                                required
                                                value={rolesForm.data[rolesKeys.accessLinks]}
                                                onChange={this.handleChange.bind(this, rolesKeys.accessLinks)}
                                            >
                                                {
                                                    this.navList.map((item) => {
                                                        return <option value={item} key={item}> {item} </option>
                                                    })
                                                }

                                            </select>
                                        </div>
                                    </div>   
                                    <div>
                                        <button className="btn btn-success w-100" type="submit"> Submit </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}