import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import "./app.css";
import Login from "../login/login";
import Home from "../home/home";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      username: null,
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("token")) {
      this.handleSetToken(sessionStorage.getItem("token"));
    }
    // back to top button logic
    var btn = $("#back-to-top-button");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });
  }

  async handleSetToken(token, isReload) {
    await this.setState({
      token: token,
      username: sessionStorage.getItem("user"),
    });
    if (isReload) {
      window.location.reload();
    }
  }

  handleLogout() {
    this.setState({
      token: null,
      username: null,
    });
  }

  render() {
    return (
      <>
        <Home
          token={this.state.token}
          username={this.state.username}
          logout={this.handleLogout.bind(this)}
        />
        <Login setToken={this.handleSetToken.bind(this)} />
        {/* Toast wrapper div  */}
        <div className="toast-wrapper"></div>
        {/* Back to top button  */}
        <button id="back-to-top-button" className="border-0">
          <FontAwesomeIcon icon={["fas", "angle-double-up"]} />
        </button>
      </>
    );
  }
}
