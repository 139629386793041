import { staffKeys, studentKeys } from 'config/entity.config';
import React from 'react';
import { convMonthToQuarter } from 'services/shared-logic.service';
import ParamsTable from '../params-table/params-table';
import './assess-pdf.scss';

export default class AssessPdf extends React.Component {
    render() {
        return (
            <section className="main-contain">
                <div className="top-header">
                    {this.props.organisation?.name}
                </div>
                <section className="header">
                    <div className="">
                        <img src={this.props.organisation?.logopath?.[0]} alt="logo" />
                    </div>
                    <section className="details">
                        <div className="card-lm">
                            <div className="key"> Player Name</div>
                            <div className="colon"> : </div>
                            <div className="value"> {this.props.student[studentKeys.firstName] + ' ' + this.props.student[studentKeys.lastName] + ' (' + this.props.student[studentKeys.studentId] + ')'}</div>
                        </div>
                        <div className="card-lm">
                            <div className="key"> Coach</div>
                            <div className="colon"> : </div>
                            <div className="value"> {this.props?.staff[staffKeys.firstName] + ' ' + this.props?.staff[staffKeys.lastName]}</div>
                        </div>
                        {
                            (this.props.entity === "academy")
                            ? <div className="card-lm">
                                <div className="key"> Batch</div>
                                <div className="colon">:</div>
                                <div className="value"> {this.props.batchCode}</div>
                            </div>
                            : <div className="card-lm">
                                <div className="key"> Standard - Division</div>
                                <div className="colon">:</div>
                                <div className="value"> {`${this.props.student.std} - ${this.props.student.div}`}</div>
                            </div>
                        }
                        <div className="card-lm">
                            <div className="key"> Assessment Period</div>
                            <div className="colon"> : </div>
                            <div className="value">{convMonthToQuarter(this.props.month)}</div>
                        </div>
                    </section>
                    <div className={this.props.organisation?.logopath?.[1] ? "" : "invisible"}>
                        <img src={this.props.organisation?.logopath?.[1]} alt="logo" />
                    </div>
                </section>
                <ParamsTable filteredAssessmentData={this.props.filteredAssessmentData} isGoalKeeping={this.props.isGoalKeeping} />
            </section>
        )
    }
}