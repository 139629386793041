import $ from "jquery";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const formatDate = (d, isIST = false) => {
  let month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  
  return isIST ? [day, month, year].join("-") : [year, month, day].join("-");
};

const checkModalAlreadyOpen = (modalName) => {
  if ($(`#${modalName}`).hasClass("show")) {
    return true;
  }
  return false;
};

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

const omitKeysInObj = (obj, omitKeysArr) => {
  return Object.keys(obj).reduce((result, key) => {
    if (!omitKeysArr.includes(key)) {
      result[key] = obj[key];
    }
    return result;
  }, {});
};

const convertEntityExcelData = (data) => {
  // considering 1st row of array as headers
  const headerKeys = data[0].data;
  const reqData = [];
  for (let i = 1; i < data.length; i++) {
    if (data[i].data && data[i].data.length === headerKeys.length) {
      reqData.push(
        headerKeys.reduce((result, key, currIdx) => {
          if (key === "batches") {
            result[key] = data[i].data[currIdx].split(",");
          } else {
            result[key] =
              data[i].data[currIdx] !== "" ? data[i].data[currIdx] : null;
          }
          result["status"] = result["status"] || "active";
          return result;
        }, {})
      );
    }
  }

  return reqData;
};

const convertEpochToLocalTime = (epoch) => {
  return new Date(epoch).toLocaleTimeString();
};

const convertEpochToDate = (epoch) => {
  return new Date(epoch).toDateString();
};

const convertEpochToLocaleDate = (epoch) => {
  return new Date(epoch).toLocaleDateString();
};

const convertObjToQueryStr = (obj) => {
  let reqStr = "";
  let i = 0;
  for (const prop in obj) {
    if (i) {
      reqStr += "&";
    }
    reqStr += `${prop}=${obj[prop]}`;
    i++;
  }
  return reqStr;
};

const convMonthToQuarter = (month) => {
  let date = new Date(month);
  const endMon = `${monthNames[date.getMonth()]} ${date.getFullYear()}`;

  const startDate = new Date(date.setMonth(date.getMonth() - 2));

  return `${monthNames[startDate.getMonth()]
    } ${startDate.getFullYear()} - ${endMon}`;
};

const convEpochToMonthYear = (epoch, dayReq) => {
  let date = new Date(epoch);
  return `${dayReq ? date.getDate() : ''} ${monthNames[date.getMonth()]} ${date.getFullYear()}`.trim();
};

const showToast = (message, type) => {
  const toastDiv = `
          <div class="toast  ${type}" 
          ${type === "error" ? 'data-autohide="false"' : null} 
          data-delay=2000 style="position: absolute; top: 0; right: 0;">
              <div class="toast-header">
                  <strong class="mr-auto text-uppercase">${type}</strong>
                  <button type="button" class="ml-2 close" data-dismiss="toast">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="toast-body font-weight-bold">
                 ${message}
              </div>
          </div>`;

  // remove previous toast if any
  $(".toast").remove();

  // Attaching the message toasts dynamically
  $(".toast-wrapper").append(toastDiv);

  $(".toast").toast("show");

  // listening to toast hide event to remove it from DOM
  $(".toast").on("hidden.bs.toast", function () {
    // Removing the toast from DOM
    $(this).remove();
  });
};

// To show success toasts
const successToast = (message) => {
  showToast(message, "success");
};

// To show error toasts
const errorToast = (message) => {
  showToast(message, "error");
};

// to round up number to 2 decimal places
const roundUpto2Decimal = (num) => {
  if (!num) return '0.00';

  return (Math.round(+num * 100) / 100).toFixed(2);
}

export {
  formatDate,
  checkModalAlreadyOpen,
  validateForm,
  omitKeysInObj,
  convertEntityExcelData,
  convertEpochToLocalTime,
  convertEpochToDate,
  convertEpochToLocaleDate,
  convertObjToQueryStr,
  convMonthToQuarter,
  convEpochToMonthYear,
  successToast,
  errorToast,
  roundUpto2Decimal
};
