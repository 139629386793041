import React from "react";
import "./header.css";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import { backupAuthorizedIds } from "config/entity.config";
import { backupDownload } from "services/entity-apis";
import { errorToast, successToast } from "services/shared-logic.service";
import { appRoutes } from 'config/app.config';
import { organisation } from "config/app.config";
export default class Header extends React.Component {

  componentDidMount() {
    // making navbar fixed conditionally
    $(window).scroll(function () {
      if ($(window).scrollTop() > $("header").height()) {
        $("header").addClass("navbar-fixed");
      }
      if ($(window).scrollTop() <= $("header").height()) {
        $("header").removeClass("navbar-fixed");
      }
    });
  }

  async handleBackupDownload() {
    // using this method to change filename to current date,
    // else can directly be downloaded using anchor tag

    const res = await backupDownload().catch((err) => {
      console.log(err);
      errorToast("Something went wrong! Please try again");
    });

    if (res && res.data) {
      // converting to a blob file
      const fileName = new Date().toLocaleDateString();
      let downloadUrl = null;
      let data = new Blob([res.data]);
      if (navigator.msSaveBlob) {
        downloadUrl = navigator.msSaveBlob(data, `${fileName}.zip`);
      } else {
        downloadUrl = window.URL.createObjectURL(data);
      }

      // temp element to download
      $(`<a href=${downloadUrl} download="${fileName}.zip"></a>`)[0].click();
      successToast("Data Backed Up Successfully!");
    }
  }

  getAuthenticatedDiv(allowedRoutes) {
    return (
      <>
        <div className="collapse navbar-collapse" id="navbarToggler">
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
            {
              allowedRoutes.map((item) => {
                return (
                  <li className="nav-item" key={item}>
                    <NavLink
                      className="nav-link"
                      to={item}
                      activeClassName="active"
                    >
                      {appRoutes?.[item]?.name}
                    </NavLink>
                  </li>
                )
              })
            }
          </ul>
        </div>
        <div className="d-flex">
          <div className="user-div"> Hi, {this.props.username} </div>
          <div className="backup-download">
            {backupAuthorizedIds.indexOf(sessionStorage.getItem("userId")) !==
              -1 && (
                <button
                  className="btn btn-outline-primary mx-2"
                  onClick={this.handleBackupDownload.bind(this)}
                >
                  Download Backup
                </button>
              )}
          </div>
          <button
            className="btn btn-outline-primary mx-2"
            onClick={() => {
              sessionStorage.removeItem("token");
              this.props.logout();
            }}
          >
            {" "}
            Logout{" "}
          </button>
        </div>
      </>
    );
  }

  getUnauthenticatedDiv() {
    return (
      <div className="d-flex">
        <button
          className="btn btn-outline-primary mr-2"
          data-toggle="modal"
          data-target="#loginModal"
        >
          {" "}
          Login{" "}
        </button>
      </div>
    );
  }

  render() {
    let loginDiv, orgId;
    if (this.props.token) {
      const allowedRoutes = JSON.parse(sessionStorage.getItem("access")) || [];
      orgId = sessionStorage.getItem("org_id");
      loginDiv = this.getAuthenticatedDiv(allowedRoutes);
    } else {
      loginDiv = this.getUnauthenticatedDiv();
    }

    return (
      <header className="container-fluid px-0">
        <nav className="navbar navbar-expand-md navbar-light bg-light justify-content-between">
          <a className="navbar-brand" href="/">
            {orgId && <span><img src={organisation[orgId].transparentLogoPath} style={{ height: '30px' }} alt="logo" /></span>} Admin Panel
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarToggler"
            aria-controls="navbarToggler"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {loginDiv}
        </nav>
      </header>
    );
  }
}
