import React from "react";
import { CSVReader, CSVDownloader } from "react-papaparse";
import "./entity.css";
import { studentUniqueKey } from "config/entity.config";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const buttonRef = React.createRef();

export default class Entity extends React.Component {
  createEntityDataTableBody() {
    return this.props.entityDataList.map((entity, idx) => {
      return (
        <tr key={entity[this.props.uniqueKey]} className={entity['bgColor']}>
          {this.props.entityRowsKeys.map((key, index) => {
            return Array.isArray(entity[key]) ? (
              <td key={index}> {entity[key].join(", ")} </td>
            ) : (
              <td key={index}>
                {" "}
                {entity[key] !== undefined && entity[key] !== null
                  ? entity[key].toString()
                  : null}
              </td>
            );
          })}
          <td>
            <div className="d-flex justify-content-center">
              {/* Edit button displayed if it is set by entity */}
              {this.props.editAction === true && (
                <button
                  type="button"
                  className="btn hover-scale shadow-none border-0"
                  title="Edit Data"
                  onClick={() =>
                    this.props.editEntity(entity[this.props.uniqueKey])
                  }
                >
                  <FontAwesomeIcon icon={["fas", "pen"]} color="#FFA700" />
                </button>)}

              {/* Delete button displayed if it is set by entity */}
              {this.props.deleteAction === true && (
                <button
                  type="button"
                  className="btn hover-scale shadow-none border-0"
                  title="Delete Data"
                  onClick={() =>
                    this.props.deleteEntity(entity[this.props.uniqueKey])
                  }
                >
                  <FontAwesomeIcon icon={["fas", "trash"]} color="red" />
                </button>)}
              {this.props.uniqueKey === studentUniqueKey && (
                <>
                  <button
                    type="button"
                    className="btn hover-scale shadow-none border-0"
                    title="Add Transaction"
                    onClick={() =>
                      this.props.handleAddTransaction(
                        entity[this.props.uniqueKey]
                      )
                    }
                  >
                    <FontAwesomeIcon icon={["fas", "file-invoice"]} color="#32a875" />
                  </button>
                  <button
                    type="button"
                    className="btn hover-scale shadow-none border-0"
                    title="Download Last Receipt"
                    onClick={() =>
                      this.props.handleDownloadTransaction(
                        entity[this.props.uniqueKey]
                      )
                    }
                  >
                    <FontAwesomeIcon icon={["fas", "file-pdf"]} color="#eb345e" />
                  </button>
                </>
              )}
            </div>
          </td>
        </tr>
      );
    });
  }

  createEntityDataTableHeader() {
    return (
      <tr>
        {this.props.entityHeaderKeys.map((key, index) => {
          return (
            <th scope="col" key={index}>
              {" "}
              {key}{" "}
            </th>
          );
        })}
        <th scope="col" className="text-center"> Actions </th>
      </tr>
    );
  }

  handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  handleOnFileLoad = (data) => {
    // comparing header keys with entity header key
    if (
      JSON.stringify(data[0].data) ===
      JSON.stringify(Object.values(this.props.entityExcelKeys))
    ) {
      console.log(data);
      if (data[1].data.length !== data[0].data.length) {
        console.log("Data not sufficient!");
      } else {
        this.props.handleBulkInsert(data);
      }
    } else {
      console.log("Wrong Excel!");
      alert(
        "The sheet you uploaded either has wrong information or does not belong to this section, kindly check and upload correct sheet"
      );
    }
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  render() {
    let mainDiv;
    if (this.props.loading) {
      mainDiv = (
        <div className="d-flex justify-content-center p-4">
          {/* <div className="dot-flashing"></div> */}
          <div id="preloader">
            <div id="loader"></div>
          </div>
        </div>
      );
    } else {
      mainDiv = (
        <div className="table-responsive">
          <table className="table">
            <thead className="thead-dark">
              {this.createEntityDataTableHeader()}
            </thead>
            <tbody>{this.createEntityDataTableBody()}</tbody>
          </table>
        </div>
      );
    }

    return (
      <div className="mt-4 px-3">
        <div className="d-flex justify-content-between flex-wrap mb-4">
          <div className="d-flex align-items-center">
            <div className="entity-title">
              {" "}
              {this.props.entityName} ({this.props.entityDataList.length}){" "}
            </div>
            {this.props.batches && (
              <div className="batch-filter ml-2">
                <DropdownButton
                  title={this.props.selectedBatch || "Filter By Batch"}
                  variant="secondary"
                  className="ml-2"
                >
                  <Dropdown.Item
                    key="all"
                    onClick={() => this.props.onBatchFilter("all")}
                  >
                    All Batches
                  </Dropdown.Item>
                  {this.props.batches.map((batch) => {
                    return (
                      <Dropdown.Item
                        key={batch.code}
                        onClick={() => this.props.onBatchFilter(batch.code)}
                      >
                        {batch.code}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </div>
            )}
            {this.props.hasSearch && (
              <div className="search-player ml-2">
                <input
                  type="search"
                  placeholder="Search By Player Name"
                  onInput={(event) => this.props.onSearch(event)}
                />
              </div>
            )}
            {this.props.hasActiveToggle && (
              <div className="form-check ml-2">
                <input className="form-check-input" type="checkbox" name="showActivePlayers" id="showActivePlayers" checked={this.props.showActivePlayers === 'true'}
                  value={this.props.showActivePlayers === 'true' ? 'false' : 'true'}
                  onChange={this.props.onActivePlayerToggle}
                />
                <label className="form-check-label" htmlFor="showActivePlayers">
                  Show Active Players
                </label>
              </div>
            )}
          </div>
          <div className="d-flex">
            {this.props.entityExcelKeys && (
              <div className="csv-reader-div">
                <CSVReader
                  ref={buttonRef}
                  onFileLoad={this.handleOnFileLoad}
                  onError={this.handleOnError}
                  noClick
                  noDrag
                  onRemoveFile={this.handleOnRemoveFile}
                  style={{
                    progressBar: {
                      backgroundColor: "white",
                    },
                  }}
                >
                  {() => (
                    <button
                      type="button"
                      className="btn btn-outline-primary mx-2"
                      onClick={this.handleOpenDialog}
                      style={{ height: "100%" }}
                    >
                      Browse & Upload file
                    </button>
                  )}
                </CSVReader>
              </div>
            )}
            {this.props.entityExcelKeys && (
              <CSVDownloader
                data={[
                  Object.values(this.props.entityExcelKeys).reduce(
                    (result, key) => {
                      result[key] = null;
                      return result;
                    },
                    {}
                  ),
                ]}
                type="button"
                className="btn btn-outline-primary mx-2"
                filename={`${this.props.entityName}_data_format`}
                bom={true}
              >
                Export CSV template
              </CSVDownloader>
            )}
            <button
              className="btn btn-outline-primary mx-2"
              data-toggle="modal"
              onClick={() => this.props.addEntity()}
              disabled={this.props.addBtnDisabled}
            >
              {" "}
              Add {this.props.entityName}{" "}
            </button>
          </div>
        </div>
        {mainDiv}
      </div>
    );
  }
}
