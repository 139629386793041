import React from "react";
import $ from 'jquery';
import './products.css'
import Entity from "components/entities/entity/entity";
import { getEntityData, addEntityData, patchEntity, deleteEntity } from 'services/entity-apis';
import {
    productsRowsKeys, productsHeaderKeys, productsKeys, productsModalName, productsUniqueKey, settingsKeys, productsRowsKeysWithoutGST, productsHeaderKeysWithoutGST
} from 'config/entity.config';
import { validateForm } from 'services/shared-logic.service';

export default class Roles extends React.Component {

    categories = ['Accessory', 'Membership'];
    constructor(props) {
        super(props);
        this.state = {
            productsList: [],
            organizationData: {},
            loading: true,
            productsForm: {
                mode: 'add',
                data: this.initializeproductsFormData()
            },
            errors: {
            },
            keyValChange: this.resetChangedKeys()
        }
    }

    initializeproductsFormData(roles = {}) {
        return {
            [productsKeys.id]: roles[productsKeys.id] || '',
            [productsKeys.category]: roles[productsKeys.category] || '',
            [productsKeys.item]: roles[productsKeys.item] || '',
            [productsKeys.period]: roles[productsKeys.period] || '',
            [productsKeys.discountPercentage]: roles[productsKeys.discountPercentage] ?? 0,
            [productsKeys.hsnCode]: roles[productsKeys.hsnCode] || null,
            [productsKeys.rate]: roles[productsKeys.rate] || '',
            [productsKeys.gstRate]: roles[productsKeys.gstRate] ?? 0,
            [productsKeys.sgstRate]: roles[productsKeys.sgstRate] ?? 0,
            [productsKeys.cgstRate]: roles[productsKeys.cgstRate] ?? 0,
            [productsKeys.igstRate]: roles[productsKeys.igstRate] ?? 0,
        }
    }

    resetChangedKeys() {
        return {
            [productsKeys.id]: false,
            [productsKeys.category]: false,
            [productsKeys.item]: false,
            [productsKeys.period]: false,
            [productsKeys.discountPercentage]: false,
            [productsKeys.hsnCode]: false,
            [productsKeys.rate]: false,
            [productsKeys.gstRate]: false,
            [productsKeys.sgstRate]: false,
            [productsKeys.cgstRate]: false,
            [productsKeys.igstRate]: false,
        }
    }

    componentDidMount() {
        this.getProductsData();
        this.getOrganizationData();
    }

    async getProductsData() {
        await this.setState({ loading: true })

        const data = await getEntityData('products').catch((err) => {
            this.setState({ loading: false })
            console.log(err);
        })

        if (data) {
            await this.setState({
                loading: false,
                productsList: data.data || []
            })
        }
    }

    async getOrganizationData() {
        const data = await getEntityData('organizations').catch((err) => {
            console.log(err);
        })

        if (data) {
            await this.setState({
                organizationData: data.data || {},
            })
        }
    }

    handleChange(key, event) {
        const value = event.target.value;

        this.setState({
            productsForm: {
                mode: this.state.productsForm.mode,
                data: { ...this.state.productsForm.data, [key]: value }
            },
            keyValChange: { ...this.state.keyValChange, [key]: true }
        })
    }

    validateFormData(id, value) {
        switch (id) {
            default:
                console.log('Validation Not Required!')
        }
    }

    async onProductsFormSubmit(e) {
        e.preventDefault();

        if (!validateForm(this.state.errors)) {
            return;
        }

        const reqData = { ...this.state.productsForm.data, period: (this.state.productsForm.data.category === 'Membership' ? this.state.productsForm.data.period : null) };
        for (const [key, value] of Object.entries(reqData)) {
            if (typeof value === 'string') {
                reqData[key] = value.trim();
            }
        }
        let productsData;
        if (this.state.productsForm.mode === 'add') {
            productsData = await addEntityData('products', reqData).catch((err) => {
                console.log(err);
            })
        } else if (this.state.productsForm.mode === 'edit') {
            const patchableData = {};
            Object.keys(reqData).forEach((item) => {
                if (this.state.keyValChange[item]) {
                    patchableData[item] = reqData[item];
                }
            })
            productsData = await patchEntity('products', reqData[productsKeys.id], patchableData).catch((err) => {
                console.log(err);
            })
        }
        if (productsData) {
            $(`#${productsModalName}`).modal('hide');
            this.getProductsData();
        }
    }

    async openAddProductsModal() {
        await this.setState({
            productsForm: {
                mode: 'add',
                data: this.initializeproductsFormData()
            }
        })
        $(`#${productsModalName}`).modal('show');
    }

    async editProducts(id) {
        const reqBat = this.state.productsList.find((item) => item[productsUniqueKey] === id);
        if (reqBat) {
            // initialize form with prefill data
            await this.setState({
                productsForm: {
                    mode: 'edit',
                    data: this.initializeproductsFormData(reqBat)
                },
                keyValChange: this.resetChangedKeys()
            });
            $(`#${productsModalName}`).modal('show');
        }
    }

    async deleteProducts(id) {
        let confirm = window.confirm("Are you sure want to delete this entry!");
        if (confirm) {
            const resData = await deleteEntity('products', id).catch((res) => {
                console.log(res);
            })

            if (resData) {
                console.log(resData);
                this.getProductsData();
            }
        }
    }

    render() {
        const { productsList, productsForm, organizationData } = this.state;
        return (
            <>
                <Entity
                    entityRowsKeys={organizationData?.[settingsKeys.gstin] ? productsRowsKeys : productsRowsKeysWithoutGST} entityHeaderKeys={organizationData?.[settingsKeys.gstin] ? productsHeaderKeys : productsHeaderKeysWithoutGST}
                    uniqueKey={productsUniqueKey} entityName='Products'
                    modalName={productsModalName} entityDataList={productsList}
                    loading={this.state.loading}
                    addEntity={this.openAddProductsModal.bind(this)}
                    editEntity={this.editProducts.bind(this)}
                    deleteEntity={this.deleteProducts.bind(this)}
                    editAction={true} deleteAction={true}
                />

                <div className="modal fade" id={productsModalName} tabIndex="-1" aria-labelledby="ProductModalLabel" aria-hidden="true">
                    <div className="modal-dialog min-width">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="ProductModalLabel">Add Products Details</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.onProductsFormSubmit.bind(this)}>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label htmlFor="category"> Category </label>
                                            <select id="category" className="form-control" required
                                                value={productsForm.data[productsKeys.category]} onChange={this.handleChange.bind(this, productsKeys.category)}
                                            >
                                                <option value=""> Select </option>
                                                {
                                                    this.categories.map((item) => {
                                                        return <option value={item} key={item}> {item} </option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group col-md-9">
                                            <label htmlFor="item">Item</label>
                                            <input type="text" className="form-control" id="item" placeholder="Item" required
                                                value={productsForm.data[productsKeys.item]} onChange={this.handleChange.bind(this, productsKeys.item)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        {(productsForm.data[productsKeys.category] === 'Membership') && <div className="form-group col">
                                            <label htmlFor="period">Period (in months)</label>
                                            <input type="number" className="form-control" id="period" placeholder="Period" required
                                                value={productsForm.data[productsKeys.period]} onChange={this.handleChange.bind(this, productsKeys.period)}
                                            />
                                        </div>}
                                        <div className="form-group col">
                                            <label htmlFor="rate">Rate</label>
                                            <input type="number" className="form-control" id="rate" placeholder="Rate" required
                                                value={productsForm.data[productsKeys.rate]} onChange={this.handleChange.bind(this, productsKeys.rate)}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label htmlFor="discount_percentage">Discount(%)</label>
                                            <input type="number" className="form-control" id="discount_percentage" placeholder="Discount" required
                                                value={productsForm.data[productsKeys.discountPercentage]} onChange={this.handleChange.bind(this, productsKeys.discountPercentage)}
                                            />
                                        </div>
                                        {organizationData?.[settingsKeys.gstin] && <div className="form-group col">
                                            <label htmlFor="hsn_code">HSN</label>
                                            <input type="number" className="form-control" id="hsn_code" placeholder="HSN" required
                                                value={productsForm.data[productsKeys.hsnCode]} onChange={this.handleChange.bind(this, productsKeys.hsnCode)}
                                            />
                                        </div>}
                                    </div>
                                    {organizationData?.[settingsKeys.gstin] && <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label htmlFor="gst_rate">GST(%)</label>
                                            <input type="number" className="form-control" id="gst_rate" placeholder="GST" required
                                                value={productsForm.data[productsKeys.gstRate]} onChange={this.handleChange.bind(this, productsKeys.gstRate)}
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="sgst_rate">SGST(%)</label>
                                            <input type="number" className="form-control" id="sgst_rate" placeholder="SGST" required
                                                value={productsForm.data[productsKeys.sgstRate]} onChange={this.handleChange.bind(this, productsKeys.sgstRate)}
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="cgst_rate">CGST(%)</label>
                                            <input type="number" className="form-control" id="cgst_rate" placeholder="CGST" required
                                                value={productsForm.data[productsKeys.cgstRate]} onChange={this.handleChange.bind(this, productsKeys.cgstRate)}
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="igst_rate">IGST(%)</label>
                                            <input type="number" className="form-control" id="igst_rate" placeholder="IGST" required
                                                value={productsForm.data[productsKeys.igstRate]} onChange={this.handleChange.bind(this, productsKeys.igstRate)}
                                            />
                                        </div>
                                    </div>}
                                    <div>
                                        <button className="btn btn-success w-100" type="submit"> Submit </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}