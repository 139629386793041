import React from 'react';
import { Table, Dropdown, DropdownButton } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getBatchWiseData, getBatchSummary, getEntityData } from "services/entity-apis";
import { batchKeys, studentKeys } from 'config/entity.config';
import './summary.scss';
import PlayerWise from './player-wise/player-wise';

export default class Summary extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            batches: [],
            selectedBatch: null,
            students: [],
            tableData: [],
            year: new Date().getFullYear(),
            selectedStudent: null,
            dataProgress: true
        }
    }

    async componentDidMount() {
        this.getBatches();
    }

    async getBatches() {
        const batches = await getEntityData('batches').catch(err => {
            console.log(err);
        })

        if (batches) {
            this.setState({
                batches: batches.data,
                dataProgress: false
            })
        }
    }

    async setSelectedBatch(batch) {
        this.setState({
            dataProgress: true
        })
        const studentsData = await getBatchWiseData('students', batch).catch((err) => {
            console.log(err);
        });

        if (studentsData) {
            this.setState({
                students: studentsData.data,
                selectedBatch: batch,
                tableData: []
            })

            for (let i = 11; i >= 0; i--) {
                const year = this.state.year;
                const startTm = +new Date(year, i, 1);
                const endTm = +new Date(year, i + 1, 0);
                const summaryData = await getBatchSummary(batch, endTm / 1000, startTm / 1000).catch(err => {
                    console.log(err);
                })

                if (summaryData) {
                    studentsData.data.forEach((student) => {
                        const present = summaryData.data.find((item) => (item.status === 'PRESENT' && item[studentKeys.studentId] === student[studentKeys.studentId]))?.count;
                            // absent = summaryData.data.find((item) => (item.status === 'ABSENT' && item[studentKeys.studentId] === student[studentKeys.studentId]))?.count,
                            // late = summaryData.data.find((item) => (item.status === 'LATE' && item[studentKeys.studentId] === student[studentKeys.studentId]))?.count,
                            // total = +(present || 0) + +(absent || 0) + +(late || 0);

                        const tableData = this.state.tableData;
                        const stuIdx = tableData.findIndex(item => item.student[studentKeys.studentId] === student[studentKeys.studentId])
                        if (stuIdx !== -1) {
                            tableData[stuIdx][i] = {
                                present
                            }
                            tableData[stuIdx]['total'] += (+present || 0)
                        } else {
                            tableData.push({
                                student,
                                [i]: {
                                    present
                                },
                                total: (+present || 0)
                            })
                        }
                        this.setState({
                            tableData
                        })
                    })
                }
            }
        }
        this.setState({
            dataProgress: false
        })
    }

    async onYearChange(year) {
        await this.setState({
            year
        });
        if (this.state.selectedBatch) {
            this.setSelectedBatch(this.state.selectedBatch);
        }
    }

    setSelectedStudent(student) {
        this.setState({
            selectedStudent: student,
        })
    }

    removeSelectedStudent() {
        this.setState({
            selectedStudent: null,
        })
    }

    render() {
        const { batches, selectedBatch, selectedStudent, year, dataProgress, tableData } = this.state;
        return (
            <>
                {dataProgress && <div className="d-flex justify-content-center p-4">
                    <div id="preloader">
                        <div id="loader"></div>
                    </div>
                </div>}
                {
                    (!dataProgress && selectedStudent) && <div className="container mt-2">
                        <button className="btn btn-link" onClick={this.removeSelectedStudent.bind(this)}>
                            <span className="mr-3"><FontAwesomeIcon icon={["fas", "arrow-left"]} /></span>
                            Back
                        </button>
                        <PlayerWise student={selectedStudent} year={year} />
                    </div>
                }
                {(!dataProgress && !selectedStudent) && <div className="container mt-2">
                    <div className="d-flex">
                        <DropdownButton title={selectedBatch || "Select Batch"} variant="secondary">
                            {
                                batches.map((batch) => {
                                    return (
                                        <Dropdown.Item key={batch[batchKeys.code]} onClick={this.setSelectedBatch.bind(this, batch[batchKeys.code])}>{batch[batchKeys.code]}</Dropdown.Item>
                                    )
                                })
                            }
                        </DropdownButton>

                        <DropdownButton title={year || "Select Year"} variant="secondary" className="ml-2">
                            {
                                [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029].map((year) => {
                                    return (
                                        <Dropdown.Item key={year} onClick={this.onYearChange.bind(this, year)}>{year}</Dropdown.Item>
                                    )
                                })
                            }
                        </DropdownButton>

                    </div>

                    <div className="mt-2">
                        <Table striped bordered hover>
                            <thead>
                                <tr>

                                    <th rowSpan="2"> Student name </th>
                                    <th colSpan="3"> Q1 </th>
                                    <th colSpan="3"> Q2 </th>
                                    <th colSpan="3"> Q3 </th>
                                    <th colSpan="3"> Q4 </th>
                                    <th rowSpan="2"> Total </th>
                                </tr>
                                <tr>
                                    <th> Jan </th>
                                    <th> Feb </th>
                                    <th> Mar </th>
                                    <th> Apr </th>
                                    <th> May </th>
                                    <th> Jun </th>
                                    <th> Jul </th>
                                    <th> Aug </th>
                                    <th> Sept </th>
                                    <th> Oct </th>
                                    <th> Nov </th>
                                    <th> Dec </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tableData.map((data) => {
                                        return (
                                            <tr key={data?.student?.[studentKeys.studentId]}>
                                                <td>
                                                    <button className="btn btn-link p-0" onClick={this.setSelectedStudent.bind(this, data?.student)}>{data?.student?.[studentKeys.firstName] + ' ' + data?.student?.[studentKeys.lastName]}</button>
                                                </td>
                                                {
                                                    Array(12).fill(0).map((_, index) => {
                                                        return (
                                                            <td key={index}>{data?.[index]?.present || '-'}</td>
                                                        )
                                                    })
                                                }
                                                <td>{data?.total}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>

                </div>
                }
            </>
        )
    }
}