import React from "react";
import "./transaction-modal.scss";
import { studentKeys, transactionModalName } from "config/entity.config";
import { addPaymentEntry } from "services/entity-apis";
import { errorToast, successToast, formatDate } from "services/shared-logic.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default class TransactionModal extends React.Component {
  minAmount = null;
  maxAmount = null;
  stepSize = null;

  constructor(props) {
    super(props);
    this.state = this.initializeState();
  }

  initializeState() {
    return {
      studentId: this.props.student
        ? this.props.student[studentKeys.studentId]
        : "",
      selectedProduct: "",
      quantity: 1,
      startDate: formatDate(new Date()),
      endDate: formatDate(new Date()),
      addedProducts: [],
      transactionsMode: "cash",
      name: this.props.student
        ? this.props.student[studentKeys.firstName] +
        " " +
        this.props.student[studentKeys.lastName]
        : "",
      email: this.props.student ? this.props.student[studentKeys.email] : "",
      saving: false,
    };
  }

  componentDidMount() {
    this.props.setOpen(this.onOpen.bind(this));
  }

  onOpen() {
    this.setState(this.initializeState());
  }

  handleChange(key, event) {
    this.setState({
      [key]: event.target.value
    });
    if(key === 'selectedProduct') {
      this.setState({
        quantity: 1,
        startDate: formatDate(new Date()),
        endDate: formatDate(new Date())
      });
    }
  }

  async handleAddItem() {
    const selectedProduct = JSON.parse(this.state.selectedProduct);
    await this.setState({
      addedProducts: [...this.state.addedProducts, { item: selectedProduct, quantity: this.state.quantity, startDate: (selectedProduct.category === 'Membership' ? this.state.startDate : null), endDate: (selectedProduct.category === 'Membership' ? this.state.endDate : null) }],
      selectedProduct: '',
      quantity: 1,
      startDate: formatDate(new Date()),
      endDate: formatDate(new Date())
    });
  }

  async handleRemoveItem(id) {
    await this.setState({
      addedProducts: this.state.addedProducts.filter(item => item.item?.id !== id)
    });
  }

  processSelectedProducts() {
    let totalAmount = 0, transactionItems = [];

    transactionItems = (this.state.addedProducts || [])?.map((item) => {

      // calculate final product price
      const itemtotalAmount = item.quantity * (item.item?.rate - (((item.item?.discount_percentage - item.item?.cgst_rate - item.item?.sgst_rate - item.item?.igst_rate) / 100) * item.item?.rate));
      totalAmount+=itemtotalAmount;
      item['totalAmount'] = itemtotalAmount;

      if (item.item?.category === 'Membership' && item.startDate) {
        item.startDate = +new Date(item.startDate) / 1000;
        item.endDate = +new Date(item.endDate) / 1000;
      }
      return item;
    });

    return {
      totalAmount,
      transactionItems
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.setState({
      saving: true,
    });

    const { totalAmount, transactionItems } = this.processSelectedProducts();
    const reqParams = {
      transactions_student_id: this.state.studentId,
      transactions_date: (+new Date() / 1000).toFixed(0),
      transactions_mode: this.state.transactionsMode,
      transactions_amount: totalAmount,
      transactions_items_data: transactionItems
    };
    const pRes = await addPaymentEntry(reqParams).catch((err) => {
      this.setState({
        saving: false,
      });
      console.log(err);
      errorToast(err);
    });

    if (pRes) {
      console.log(pRes);
      successToast("Transaction Added Successfully!");
      this.setState(this.initializeState());
    }
  }

  getSelectedProductCategory() {
    if (this.state.selectedProduct) return JSON.parse(this.state.selectedProduct)?.category;
    return null;
  }

  render() {
    return (
      <div
        className="modal fade"
        id={transactionModalName}
        tabIndex="-1"
        aria-labelledby="transactionModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="loginModalLabel">
                Add Transaction
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={this.handleSubmit.bind(this)}>
                <div className="form-row">
                  <div className="form-group col-md-3">
                    <label htmlFor="studentId">Player ID</label>
                    <input
                      type="text"
                      className="form-control"
                      id="studentId"
                      placeholder="Player ID"
                      value={this.state.studentId}
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="studentName">Player Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="studentName"
                      placeholder="Player Name"
                      value={this.state.name}
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-5">
                    <label htmlFor="studentEmail">Player Email</label>
                    <input
                      type="text"
                      className="form-control"
                      id="studentEmail"
                      placeholder="Player Email"
                      value={this.state.email}
                      readOnly
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label htmlFor="paymentMode"> Payment Mode *</label>
                    <select
                      id="paymentMode"
                      className="form-control"
                      required
                      value={this.state.transactionsMode}
                      onChange={this.handleChange.bind(this, "transactionsMode")}
                    >
                      <option value="cash"> by Cash </option>
                      <option value="bank"> UPI/Bank Transfer </option>
                      <option value="razorpay">Razorpay</option>
                    </select>
                  </div>
                </div>
                <div className="form-row table-responsive-md">
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th className="align-middle border-0 font-weight-bold bg-white">
                          Item
                        </th>
                        <th className="align-middle border-0 font-weight-bold bg-white">
                          Quantity
                        </th>
                        <th className="align-middle border-0 font-weight-bold bg-white">
                          Start Date
                        </th>
                        <th className="align-middle border-0 font-weight-bold bg-white">
                          End Date
                        </th>
                        <th className="align-middle border-0 font-weight-bold bg-white"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <select id="products" className="form-control" value={this.state.selectedProduct}
                            onChange={this.handleChange.bind(this, "selectedProduct")}
                          >
                            <option value="" disabled>Select Item</option>
                            {
                              this.props.products?.filter(item => !this.state.addedProducts?.find(ap => ap.item?.id === item.id)).map((item) =>
                                <option value={JSON.stringify(item)} key={item.id}> {item.item} </option>
                              )
                            }
                          </select>
                        </td>
                        <td>
                          {this.getSelectedProductCategory() === 'Accessory' ? <input
                            type="number"
                            className="form-control"
                            placeholder="Quantity"
                            value={this.state.quantity}
                            onChange={this.handleChange.bind(this, "quantity")}
                            min={1}
                          /> : this.state.quantity}
                        </td>
                        <td>
                          {this.getSelectedProductCategory() === 'Membership' ? <input
                            type="date"
                            className="form-control"
                            placeholder="Transaction Date"
                            value={this.state.startDate}
                            onChange={this.handleChange.bind(this, "startDate")}
                          /> : '-'}
                        </td>
                        <td>
                          {this.getSelectedProductCategory() === 'Membership' ? <input
                            type="date"
                            className="form-control"
                            placeholder="Transaction Date"
                            value={this.state.endDate}
                            onChange={this.handleChange.bind(this, "endDate")}
                          /> : '-'}
                        </td>
                        <td className="align-middle">
                          <button type="button" className="btn btn-sm btn-primary" disabled={!this.state.selectedProduct} onClick={this.handleAddItem.bind(this)}>
                            <FontAwesomeIcon icon={["fas", "plus"]} />
                          </button>
                        </td>
                      </tr>
                      {
                        this.state.addedProducts?.map((product) => {
                          return (
                            <tr key={'selected_' + product?.item?.id}>
                              <td className="font-weight-normal">{product.item?.item}</td>
                              <td className="font-weight-normal">{product.quantity}</td>
                              <td className="font-weight-normal">{product.startDate || '-'}</td>
                              <td className="font-weight-normal">{product.endDate || '-'}</td>
                              <td>
                                <button type="button" className="btn btn-sm text-danger" onClick={this.handleRemoveItem.bind(this, product.item?.id)}>
                                  <FontAwesomeIcon icon={["fas", "trash"]} />
                                </button>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
                <div>
                  <button
                    className="btn btn-success w-100"
                    type="submit"
                    disabled={this.state.saving}
                  >
                    {" "}
                    Submit{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
