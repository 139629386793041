import React from "react";
import $ from 'jquery';
import './batch.css'
import Entity from "components/entities/entity/entity";
import { getEntityData, addEntityData, patchEntity, deleteEntity } from 'services/entity-apis';
import {
    batchKeys, batchHeaderKeys, batchRowsKeys, batchUniqueKey,
    batchModalName, settingsKeys
} from 'config/entity.config';
import { validateForm } from 'services/shared-logic.service';

export default class Student extends React.Component {

    minCapacity = 5;
    maxCapacity = 100;
    schoolList = [];
    ageGroupList = ['U5', 'U6', 'U7', 'U8', 'U9', 'U10', 'U12', 'U14', 'U16', 'U18', '18+'];
    daysList = ['Monday', 'Tuesday', 'Wednesday', 'Thursday',
        'Friday', 'Saturday', 'Sunday'];

    constructor(props) {
        super(props);
        this.state = {
            locationList: [],
            batchList: [],
            sportsList: [],
            loading: true,
            batchForm: {
                mode: 'add',
                data: this.initializeBatchFormData()
            },
            errors: {
            },
            keyValChange: this.resetChangedKeys()
        }
    }

    initializeBatchFormData(batch = {}) {
        return {
            [batchKeys.code]: batch[batchKeys.code] || null,
            [batchKeys.locationCode]: batch[batchKeys.locationCode] || '',//(this.locationList && this.locationList[0] ? this.locationList[0].code : ''),
            [batchKeys.ageGroup]: batch[batchKeys.ageGroup] || '',//this.ageGroupList[0],
            [batchKeys.capacity]: batch[batchKeys.capacity] || 5,
            [batchKeys.dayTime]: batch[batchKeys.dayTime] || [
                { [batchKeys.day]: this.daysList[0], [batchKeys.startTime]: '', [batchKeys.endTime]: '' }
            ],
            [batchKeys.sportsName]: batch[batchKeys.sportsName] || '',
        }
    }

    resetChangedKeys() {
        return {
            [batchKeys.code]: false,
            [batchKeys.locationCode]: false,
            [batchKeys.capacity]: false,
            [batchKeys.dayTime]: false,
            [batchKeys.sportsName]: false
        }
    }

    componentDidMount() {
        this.getBatchData();
        this.getDependentData();
        this.getOrgData();
    }

    async getBatchData() {
        await this.setState({ loading: true })

        const data = await getEntityData('batches').catch((err) => {
            this.setState({ loading: false })
            console.log(err);
        })

        if (data) {
            await this.setState({
                loading: false,
                batchList: data.data || []
            })
        }
    }

    async getOrgData() {
        await this.setState({ loading: true })

        const data = await getEntityData('organizations').catch((err) => {
            this.setState({ loading: false })
            console.log(err);
        })

        if (data) {
            await this.setState({
                loading: false,
                orgList: data.data || [],
                sportsList: data.data?.[settingsKeys.sportsProvided]
            })
        }
    }

    async getDependentData() {
        Promise.all([this.getLocationData()]).then(async (res) => {
            if (res[0]) {
                this.setState({
                    locationList: res[0].data || []
                })
            }
            await this.setState({
                batchForm: {
                    mode: this.state.batchForm.mode,
                    data: {
                        ...this.state.batchForm.data,
                        [batchKeys.locationCode]: this.state.locationList && this.state.locationList[0] ? this.state.locationList[0].code : null,
                    }
                },
            })
        })
    }

    getLocationData() {
        return getEntityData('locations').catch((err) => {
            console.log(err);
        })
    }

    handleChange(key, event) {
        const value = event.target.value;

        this.setState({
            batchForm: {
                mode: this.state.batchForm.mode,
                data: { ...this.state.batchForm.data, [key]: value }
            },
            keyValChange: { ...this.state.keyValChange, [key]: true }
        })
    }

    handleDayTimeChange(index, key, event) {

        const value = event.target.value;
        const dayTimeData = this.state.batchForm.data[batchKeys.dayTime];
        dayTimeData[index][key] = value;

        this.setState({
            batchForm: {
                mode: this.state.batchForm.mode,
                data: {
                    ...this.state.batchForm.data, [batchKeys.dayTime]: dayTimeData
                }
            },
            keyValChange: { ...this.state.keyValChange, [batchKeys.dayTime]: true }
        })
    }

    handleNewDayAdd() {
        this.setState({
            batchForm: {
                mode: this.state.batchForm.mode,
                data: {
                    ...this.state.batchForm.data, [batchKeys.dayTime]: [
                        ...this.state.batchForm.data[batchKeys.dayTime],
                        { [batchKeys.day]: this.daysList[0], [batchKeys.startTime]: '', [batchKeys.endTime]: '' }
                    ]
                }
            }
        })
    }

    validateFormData(id, value) {
        switch (id) {
            default:
                console.log('Validation Not Required!')
        }
    }

    async onBatchFormSubmit(e) {
        e.preventDefault();

        if (!validateForm(this.state.errors)) {
            return;
        }

        const reqData = { ...this.state.batchForm.data };
        for (const [key, value] of Object.entries(reqData)) {
            if (typeof value === 'string') {
                reqData[key] = value.trim();
            }
        }

        let batchData;
        if (this.state.batchForm.mode === 'add') {
            delete reqData[batchKeys.code];
            batchData = await addEntityData('batches', reqData).catch((err) => {
                console.log(err);
            })
        } else if (this.state.batchForm.mode === 'edit') {
            const patchableData = {};
            Object.keys(reqData).forEach((item) => {
                if (this.state.keyValChange[item]) {
                    patchableData[item] = reqData[item];
                }
            })
            batchData = await patchEntity('batches', reqData[batchKeys.code], patchableData).catch((err) => {
                console.log(err);
            })
        }
        if (batchData) {
            $(`#${batchModalName}`).modal('hide');
            this.getBatchData();
        }
    }

    async openAddBatchModal() {
        await this.setState({
            batchForm: {
                mode: 'add',
                data: this.initializeBatchFormData()
            }
        })
        $(`#${batchModalName}`).modal('show');
    }

    async deleteBatch(batchCode) {
        let confirm = window.confirm("Are you sure want to delete this entry!");
        if (confirm) {
            const resData = await deleteEntity('batches', batchCode).catch((res) => {
                console.log(res);
            })

            if (resData) {
                console.log(resData);
                this.getBatchData();
            }
        }
    }

    async editBatch(batchCode) {
        const reqBat = this.state.batchList.find((item) => item[batchUniqueKey] === batchCode);
        if (reqBat) {
            // initialize form with prefill data
            await this.setState({
                batchForm: {
                    mode: 'edit',
                    data: this.initializeBatchFormData(reqBat)
                },
                keyValChange: this.resetChangedKeys()
            });
            $(`#${batchModalName}`).modal('show');
        }
    }

    render() {
        const { locationList, batchForm, sportsList } = this.state;
        return (
            <>
                <Entity
                    entityRowsKeys={batchRowsKeys} entityHeaderKeys={batchHeaderKeys}
                    uniqueKey={batchUniqueKey} entityName='Batches'
                    modalName={batchModalName} entityDataList={this.state.batchList}
                    loading={this.state.loading}
                    addBtnDisabled={(locationList.length > 0) ? false : true}
                    addEntity={this.openAddBatchModal.bind(this)}
                    deleteEntity={this.deleteBatch.bind(this)}
                    editEntity={this.editBatch.bind(this)}
                    editAction={true}
                />

                <div className="modal fade" id={batchModalName} tabIndex="-1" aria-labelledby="BatchModalLabel" aria-hidden="true">
                    <div className="modal-dialog min-width">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="BatchModalLabel">Add Batch Details</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.onBatchFormSubmit.bind(this)}>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label htmlFor="locationCode"> Location Code *</label>
                                            <select id="locationCode" className="form-control selectpicker" data-live-search="true" required
                                                value={batchForm.data[batchKeys.locationCode]} onChange={this.handleChange.bind(this, batchKeys.locationCode)}
                                            >
                                                <option value=""> Select </option>
                                                {
                                                    locationList.map((item) => {
                                                        return <option value={item.code} key={item.code}> {item.code} </option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label htmlFor="capacity">Capacity *</label>
                                            <input type="number" min={this.minCapacity} max={this.maxCapacity} className="form-control" id="capacity" placeholder="capacity"
                                                required value={batchForm.data[batchKeys.capacity]} onChange={this.handleChange.bind(this, batchKeys.capacity)}
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="ageGroup"> Age Group *</label>
                                            <select id="ageGroup" className="form-control selectpicker" data-live-search="true" required
                                                value={batchForm.data[batchKeys.ageGroup]} onChange={this.handleChange.bind(this, batchKeys.ageGroup)}
                                            >
                                                <option value=""> Select </option>
                                                {
                                                    this.ageGroupList.map((item) => {
                                                        return <option value={item} key={item}> {item} </option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="sports_name"> Sport *</label>
                                            <select id="sports_name" className="form-control selectpicker" data-live-search="true" required
                                                value={batchForm.data[batchKeys.sportsName]} onChange={this.handleChange.bind(this, batchKeys.sportsName)}
                                            >
                                                <option value=""> Select </option>
                                                {
                                                    sportsList?.map((item) => {
                                                        return <option value={item} key={item}> {item} </option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        batchForm.data[batchKeys.dayTime].map((item, index) => {
                                            return (
                                                <div className="form-row day-time-div" key={index}>
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor={`day_${index}`}> Day *</label>
                                                        <select id={`day_${index}`} className="form-control selectpicker" data-live-search="true" required
                                                            value={item[batchKeys.day]} onChange={this.handleDayTimeChange.bind(this, index, batchKeys.day)}
                                                        >
                                                            {
                                                                this.daysList.map((item) => {
                                                                    return <option value={item} key={item}> {item} </option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor={`starttime_${index}`}>Start Time *</label>
                                                        <input type="time" className="form-control" id={`starttime_${index}`} placeholder="StartTime"
                                                            required value={item[batchKeys.startTime]} onChange={this.handleDayTimeChange.bind(this, index, batchKeys.startTime)}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor={`endtime_${index}`}>End Time *</label>
                                                        <input type="time" className="form-control" id={`endtime_${index}`} placeholder="EndTime"
                                                            required value={item[batchKeys.endTime]} onChange={this.handleDayTimeChange.bind(this, index, batchKeys.endTime)}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="add-new-btn mb-2">
                                        <button className="btn btn-primary w-100" onClick={this.handleNewDayAdd.bind(this)}
                                        > + Add New Day </button>
                                    </div>
                                    <div>
                                        <button className="btn btn-success w-100" type="submit"> Submit </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}