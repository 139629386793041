import React from 'react';
import './assess-summary.scss';
import { getEntityDataByID } from 'services/entity-apis';
import AcademyPlayers from './academy-players';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorMsg from 'components/shared/error-msg';

export default class AssessSummary extends React.Component {

    constructor(props) {
        const currDate = new Date();
        const currYr = currDate.getFullYear();
        let currMon = currDate.getMonth() + 1;
        currMon = currMon > 9 ? currMon : `0${currMon}`;

        super(props);
        this.state = {
            dataProgress: true,
            batchesData: [],
            studentList: [],
            batch: null,
            selectedStudent: null,
            selectedStaff: null,
            selectedMonth: `${currYr}-${currMon}`,
            assessmentData: [],
            filteredAssessmentData: null,
            batchesAssigned: []
        };
    }

    componentDidMount() {
        Promise.all([this.getStaffData()]).then(res => {
            this.setState({
                dataProgress: false
            })
        })
    }

    async getStaffData() {
        const staffData = await getEntityDataByID('staffs', sessionStorage.getItem("userId")).catch((err) => {
            console.log(err);
        })

        if (staffData && staffData.data) {
            await this.setState({
                batchesAssigned: staffData.data.batches || []
            })
        }
    }

    async onBatchSelect(batch) {
        await this.setState({ batch });
    }

    removeSelectedSchool() {
        this.setState({
            batch: null,
        })
    }

    render() {
        const { batch, batchesAssigned } = this.state;
        return (
            <>
                {
                    (batch) && <div className="container-fluid mt-2">
                        <span>
                            <button className="btn btn-link" onClick={this.removeSelectedSchool.bind(this)}>
                                <span className="mr-3"><FontAwesomeIcon icon={["fas", "arrow-left"]} /></span>
                                Back
                            </button>
                            <span className="badge badge-info mb-0" title="School Code">{batch}</span>
                        </span>
                        <AcademyPlayers batch={batch} />
                    </div>
                }
                {(!batch) && <div className="container mt-2">
                    <div className="batch-cards row">
                        {
                            batchesAssigned?.map((item, idx) => {
                                return (
                                    <div className="col-6 col-md-4" key={item}>
                                        <div className="batch-card">
                                            <div className="action-btns d-flex justify-content-around w-100 ">
                                                <button className="action-btns" onClick={this.onBatchSelect.bind(this, item)} key={item}>{item}</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {!batchesAssigned?.length &&
                        <div style={{ marginTop: '20px' }}>
                            <ErrorMsg msg="No Batch to show !" />
                        </div>
                    }
                </div>
                }
            </>
        )
    }
}
