import { organisation } from "config/app.config";
import { settingsKeys, studentKeys } from "config/entity.config";
import React from "react";
import { convertEpochToLocaleDate, formatDate, roundUpto2Decimal } from "services/shared-logic.service";
import './receipt-pdf.scss';

export default class ReceiptPdf extends React.Component {

    createDataTableHeader() {
        return (
            <tr>
                <th scope="col" className="desc"> Item </th>
                <th scope="col" className="amount">Duration </th>
                {this.props.organizationData[settingsKeys.gstin] && <th scope="col" className="amount"> HSN </th>}
                <th scope="col" className="amount"> Qty </th>
                <th scope="col" className="amount"> Rate </th>
                <th scope="col" className="amount"> Disc (₹) </th>
                {this.props.organizationData[settingsKeys.gstin] && <><th scope="col" className="amount"> Taxable Amt (₹)</th>
                    <th scope="col" className="amount"> CGST (₹)</th>
                    <th scope="col" className="amount"> SGST (₹)</th></>}
                <th scope="col" className="amount"> Total Amt (₹)</th>
            </tr>
        )
    }

    createDataTableBody(transacDetail) {
        let totalDiscountInRupee = 0, totalDiscountedAmount = 0, totalCgstInRupee = 0, totalSgstInRupee = 0,
            globalTotalColspan = this.props.organizationData[settingsKeys.gstin] ? 9 : 5,
            totalColspan = this.props.organizationData[settingsKeys.gstin] ? 5 : 4;

        const itemsRows = transacDetail.transactions_items_data.map((item, idx) => {
            const discountInRupee = roundUpto2Decimal((item.item.discount_percentage / 100) * item.item.rate);
            const discountedAmount = roundUpto2Decimal(item.item.rate - discountInRupee);
            const cgstInRupee = roundUpto2Decimal((item.item.cgst_rate / 100) * discountedAmount);
            const sgstInRupee = roundUpto2Decimal((item.item.sgst_rate / 100) * discountedAmount);

            totalDiscountInRupee += +discountInRupee;
            totalDiscountedAmount += +discountedAmount;
            totalCgstInRupee += +cgstInRupee;
            totalSgstInRupee += +sgstInRupee;

            return (
                <tr key={idx}>
                    <td className="desc">
                        {item.item.item}
                    </td>
                    <td className="amount">{item.startDate ? convertEpochToLocaleDate(item.startDate * 1000) : ''} - {item.endDate ? convertEpochToLocaleDate(item.endDate * 1000) : ''}</td>
                    {this.props.organizationData[settingsKeys.gstin] && <td className="amount">
                        {item.item.hsn_code || '-'}
                    </td>}
                    <td className="amount">
                        {item.quantity || 1}
                    </td>
                    <td className="amount">
                        {item.item.rate || 0}
                    </td>
                    <td className="amount">
                        <span>{discountInRupee}</span>
                        <br />
                        <span className="text-muted percent-val">@{item.item.discount_percentage}%</span>
                    </td>
                    {this.props.organizationData[settingsKeys.gstin] && <><td className="amount">
                        <span>{discountedAmount}</span>
                    </td>
                        <td className="amount">
                            <span>{cgstInRupee}</span>
                            <br />
                            <span className="text-muted percent-val">@{item.item.cgst_rate}%</span>
                        </td>
                        <td className="amount">
                            <span>{sgstInRupee}</span>
                            <br />
                            <span className="text-muted percent-val">@{item.item.sgst_rate}%</span>
                        </td>
                    </>}
                    <td className="amount">
                        {roundUpto2Decimal(item.totalAmount)}
                    </td>
                </tr>
            )
        });
        return (
            <>
                {itemsRows}
                <tr className="thead-light">
                    <th colSpan={totalColspan} className="text-right font-weight-bold">Total</th>
                    <th className="amount">{roundUpto2Decimal(totalDiscountInRupee)}</th>
                    {this.props.organizationData[settingsKeys.gstin] && <><th className="amount">{roundUpto2Decimal(totalDiscountedAmount)}</th>
                        <th className="amount">{roundUpto2Decimal(totalCgstInRupee)}</th>
                        <th className="amount">{roundUpto2Decimal(totalSgstInRupee)}</th></>}
                    <th className="amount">{roundUpto2Decimal(transacDetail.transactions_amount)}</th>
                </tr>
                {this.props.organizationData[settingsKeys.gstin] && <> <tr>
                    <td colSpan={globalTotalColspan} className="text-right font-weight-bold border-0">Taxable Amount</td>
                    <td colSpan={globalTotalColspan} className="text-center font-weight-bold border-0">₹{roundUpto2Decimal(totalDiscountedAmount)}</td>
                </tr>
                <tr>
                    <td colSpan={globalTotalColspan} className="text-right font-weight-bold border-0">Total Tax</td>
                    <td colSpan={globalTotalColspan} className="text-center font-weight-bold border-0">₹{roundUpto2Decimal(totalCgstInRupee + totalSgstInRupee)}</td>
                </tr></>}
                <tr>
                    <td colSpan={globalTotalColspan} className="text-right font-weight-bold border-0">Payable Amount</td>
                    <td colSpan={globalTotalColspan} className="text-center font-weight-bold border-0">₹{roundUpto2Decimal(transacDetail.transactions_amount)}</td>
                </tr>
            </>
        )
    }

    render() {
        const { transactionData, organizationData } = this.props;
        return (
            <>
                {transactionData &&
                    <section className="main-receipt-contain">
                        <section className="company-container bg-light">
                            <section className="company-details">
                                <div className="d-flex align-items-center">
                                    <div className="comp-logo">
                                        <img src={organisation[sessionStorage.getItem("org_id")].transparentLogoPath} alt="logo" />
                                    </div>
                                    <div className="ml-2">
                                        <div className="comp-name">
                                            <span>{organizationData[settingsKeys.name]}</span>
                                        </div>
                                        <div className="comp-contact"><span>Contact :</span> <span className="text-muted">{organizationData[settingsKeys.contact]}</span></div>
                                        <div className="comp-email"><span>Email :</span> <span className="text-muted">{organizationData[settingsKeys.email]}</span></div>
                                        {organizationData[settingsKeys.gstin] && <div className="comp-gstin"><span>GSTIN :</span> <span className="text-muted">{organizationData[settingsKeys.gstin]}</span></div>}
                                        <div className="comp-gstin"><span>Address :</span> <span className="text-muted">{organizationData[settingsKeys.address]}</span></div>
                                    </div>
                                </div>
                            </section>
                            <section className="invoice-details">
                                <h3>Payment Receipt</h3>
                                <div className="inv-date"><span>Receipt Date :</span> <span className="text-muted">{formatDate(new Date(parseInt(transactionData.transactions_date) * 1000), true)}</span></div>
                                <div className="inv-no"><span>Receipt No. :</span> <span className="text-muted">{transactionData.transactions_id}</span></div>
                                <div className="payment-mode"><span>Payment Mode : </span> <span className="text-muted">{transactionData.transactions_mode}</span></div>
                            </section>
                        </section>

                        <section className="stu-details">
                            <h4>Player Information</h4>
                            <table className="mt-2 table table-bordered">
                                <tbody>
                                    <tr>
                                        <th>Player's Name</th>
                                        <td>{this.props.studentDetails[studentKeys.firstName] + ' ' + this.props.studentDetails[studentKeys.lastName]}</td>
                                    </tr>
                                    <tr>
                                        <th>Batch Code</th>
                                        <td>{this.props.studentDetails[studentKeys.batches].join(', ')}</td>
                                    </tr>
                                    <tr>
                                        <th>Contact</th>
                                        <td>{this.props.studentDetails[studentKeys.mobile]}</td>
                                    </tr>
                                    <tr>
                                        <th>Email</th>
                                        <td>{this.props.studentDetails[studentKeys.email]}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>

                        <section className="transaction-body">
                            <h4>Item's Information</h4>
                            <table className="table table-bordered border-0">
                                <thead className="thead-dark">
                                    {this.createDataTableHeader()}
                                </thead>
                                <tbody>
                                    {this.createDataTableBody(transactionData)}
                                </tbody>
                            </table>
                        </section>
                        <section className="footer">
                            <div className="text-muted">
                                <span>*This is a system generated report. No signature is required.</span>
                            </div>
                        </section>
                    </section>}
            </>
        )
    }
}