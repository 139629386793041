
import ReactDOMServer from "react-dom/server";
import htmlToPdf from "html-to-pdf-js";
import ReceiptPdf from "components/entities/student/receipt-pdf/receipt-pdf";
import {
    studentKeys
} from "config/entity.config";
import {
    convEpochToMonthYear,
} from "./shared-logic.service";

// Download transaction receipt
const downloadPaymentReceipt = (reqStu, transacData, organizationData) => {
    const input = ReactDOMServer.renderToStaticMarkup(
        <ReceiptPdf
            studentDetails={reqStu}
            transactionData={transacData}
            organizationData={organizationData}
        />
    );
    var opt = {
        margin: 5,
        filename: `Receipt_${reqStu[studentKeys.firstName]}_${reqStu[studentKeys.lastName]
            }_${convEpochToMonthYear(transacData.transactions_date * 1000)}`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
            dpi: 192,
            scale: 4,
            letterRendering: true,
            useCORS: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    htmlToPdf(input, opt);
}

export {
    downloadPaymentReceipt
}