import React from "react";
import $ from 'jquery';
import './staff.css'
import Entity from "components/entities/entity/entity";
import { getEntityData, addEntityData, patchEntity, deleteEntity, entityPhotoPatch } from 'services/entity-apis';
import {
    staffKeys, staffExcelKeys, staffHeaderKeys,
    staffRowsKeys, staffUniqueKey, staffModalName, nameRegx, emailRegx, mobileRegx
} from 'config/entity.config';
import { formatDate, validateForm, convertEntityExcelData } from 'services/shared-logic.service';

export default class Staff extends React.Component {

    minJoiningDate;
    maxJoiningDate;
    minBirthDate;
    maxBirthDate;
    minAge = 18;
    maxAge = 61;
    bloodGroupList = ['A+','A-','B+','B-','O+','O-','AB+','AB-'];

    constructor(props) {
        super(props);
        this.setMinMaxJoiningDate();
        this.setMinMaxBirthDate();
        this.state = {
            batchList: [],
            staffList: [],
            rolesData: [],
            loading: true,
            staffForm: {
                mode: 'add',
                data: this.initializeStaffFormData()
            },
            errors: {
                firstName: '',
                lastName: '',
                email: '',
                mobile: '',
                football_education: '',
                specialisation: ''
            },
            keyValChange: this.resetChangedKeys()
        }
    }

    setMinMaxJoiningDate() {
        var d = new Date();
        d.setDate(d.getDate() - 7);
        this.minJoiningDate = formatDate(new Date(2012,0,1));

        this.maxJoiningDate = formatDate(new Date());
    }

    setMinMaxBirthDate() {
        var d1 = new Date();
        d1.setDate(d1.getDate() - (61 * 365));
        this.minBirthDate = formatDate(d1);

        var d2 = new Date();
        d2.setDate(d2.getDate() - (18 * 365));
        this.maxBirthDate = formatDate(d2);
    }

    initializeStaffFormData(staff = {}) {
        return {
            [staffKeys.staffId]: staff[staffKeys.staffId] || null,
            [staffKeys.photo]: React.createRef(),
            [staffKeys.firstName]: staff[staffKeys.firstName] || '',
            [staffKeys.lastName]: staff[staffKeys.lastName] || '',
            [staffKeys.dob]: staff[staffKeys.dob] || 18,
            [staffKeys.email]: staff[staffKeys.email] || '',
            [staffKeys.address]: staff[staffKeys.address] || '',
            [staffKeys.mobile]: staff[staffKeys.mobile] || '',
            [staffKeys.designation]: staff[staffKeys.designation] || '',
            [staffKeys.batches]: staff[staffKeys.batches] || [],
            [staffKeys.role]: staff[staffKeys.role] || '',
            [staffKeys.specialisation]: staff[staffKeys.specialisation] || '',
            [staffKeys.education]: staff[staffKeys.education] || '',
            [staffKeys.footballEducation]: staff[staffKeys.footballEducation] || '',
            [staffKeys.isAdmin]: (staff[staffKeys.isAdmin] ? staff[staffKeys.isAdmin].toString() : null) || "false",
            [staffKeys.joiningDate]: staff[staffKeys.joiningDate] || '',
            [staffKeys.joiningMethod]: staff[staffKeys.joiningMethod] || 'online',
            [staffKeys.bloodGroup]: staff[staffKeys.bloodGroup] || ''
        }
    }

    resetChangedKeys() {
        return {
            [staffKeys.staffId]: false,
            [staffKeys.firstName]: false,
            [staffKeys.lastName]: false,
            [staffKeys.dob]: false,
            [staffKeys.email]: false,
            [staffKeys.address]: false,
            [staffKeys.mobile]: false,
            [staffKeys.designation]: false,
            [staffKeys.batches]: false,
            [staffKeys.role]: false,
            [staffKeys.specialisation]: false,
            [staffKeys.education]: false,
            [staffKeys.footballEducation]: false,
            [staffKeys.isAdmin]: false,
            [staffKeys.joiningDate]: false,
            [staffKeys.joiningMethod]: false,
            [staffKeys.bloodGroup]: false
        }
    }

    componentDidMount() {
        this.getStaffData();
        this.getBatchData();
        this.getRolesData();
    }

    async getStaffData() {
        await this.setState({ loading: true })

        const data = await getEntityData('staffs').catch((err) => {
            this.setState({ loading: false })
            console.log(err);
        })

        if (data) {
            await this.setState({
                loading: false,
                staffList: data.data || []
            })
        }

    }

    async getBatchData() {
        const batchData = await getEntityData('batches').catch((err) => {
            console.log(err);
        })
        if (batchData) {
            this.setState({
                batchList: batchData.data || []
            })
        }
    }

    async getRolesData() {
        const rolesData = await getEntityData('roles').catch((err) => {
            console.log(err);
        })
        if (rolesData) {
            this.setState({
                rolesData: rolesData.data || []
            })
        }
    }

    handleChange(key, event) {
        this.validateFormData(event.target.id, event.target.value);
        const value = key !== staffKeys.batches ? event.target.value :
            Array.from(event.target.selectedOptions, option => option.value);

        this.setState({
            staffForm: {
                mode: this.state.staffForm.mode,
                data: { ...this.state.staffForm.data, [key]: value }
            },
            keyValChange: { ...this.state.keyValChange, [key]: true }
        })
    }

    handleIsAdminChange(event) {
        const value = event.target.value;
        this.setState({
            staffForm: {
                mode: this.state.staffForm.mode,
                data: { ...this.state.staffForm.data, [staffKeys.isAdmin]: value }
            },
            keyValChange: { ...this.state.keyValChange, [staffKeys.isAdmin]: true }
        })
    }

    validateFormData(id, value) {
        switch (id) {
            case staffKeys.firstName:
                this.setState({
                    errors: {
                        ...this.state.errors, firstName: nameRegx.test(value)
                            ? ''
                            : 'Please Enter Valid First Name'
                    }
                });
                break;
            case staffKeys.lastName:
                this.setState({
                    errors: {
                        ...this.state.errors, lastName: nameRegx.test(value)
                            ? ''
                            : 'Please Enter Valid Last Name'
                    }
                });
                break;
            case staffKeys.email:
                this.setState({
                    errors: {
                        ...this.state.errors, email: emailRegx.test(value)
                            ? ''
                            : 'Please Enter Valid Email ID'
                    }
                });
                break;
            case staffKeys.mobile:
                this.setState({
                    errors: {
                        ...this.state.errors, mobile: mobileRegx.test(value)
                            ? ''
                            : 'Please Enter Valid Mobile Number'
                    }
                });
                break;
            case staffKeys.footballEducation:
                this.setState({
                    errors: {
                        ...this.state.errors, football_education: nameRegx.test(value)
                            ? ''
                            : 'Please Enter details upto 25 characters'
                    }
                });
                break;
            case staffKeys.specialisation:
                this.setState({
                    errors: {
                        ...this.state.errors, specialisation: nameRegx.test(value)
                            ? ''
                            : 'Please Enter details upto 25 characters'
                    }
                });
                break;
            default:
                console.log('Validation Not Required!')
        }
    }

    async onStaffFormSubmit(e) {
        e.preventDefault();

        if (!validateForm(this.state.errors)) {
            return;
        }

        const reqData = {
            ...this.state.staffForm.data,
            [staffKeys.isAdmin]: this.state.staffForm.data[staffKeys.isAdmin] === 'true' ? true : false
        };

        for (const [key, value] of Object.entries(reqData)) {
            if (typeof value === 'string') {
                reqData[key] = value.trim();
            }
        }

        delete reqData[staffKeys.photo];

        if (this.state.staffForm.mode === 'add') {
            delete reqData[staffKeys.staffId];
            const staffData = await addEntityData('staffs', reqData).catch((err) => {
                console.log(err);
            })
            if (staffData) {
                this.patchStaffPhoto(staffData.data[staffKeys.staffId])
            }
        } else if (this.state.staffForm.mode === 'edit') {
            const patchableData = {};
            Object.keys(reqData).forEach((item) => {
                if (this.state.keyValChange[item]) {
                    patchableData[item] = reqData[item];
                }
            })
            await patchEntity('staffs', reqData[staffKeys.staffId], patchableData).catch((err) => {
                console.log(err);
            })
            this.patchStaffPhoto(reqData[staffKeys.staffId])
        }

    }

    async patchStaffPhoto(staffId) {
        if (this.state.staffForm.data[staffKeys.photo].current.files[0]) {
            const formData = new FormData()
            formData.append('photo', this.state.staffForm.data[staffKeys.photo].current.files[0]);
            const photoPatchData = await entityPhotoPatch('staffs', staffId, formData).catch(err => {
                console.log(err);
            })
            if (photoPatchData) {
                $(`#${staffModalName}`).modal('hide');
                this.getStaffData();
            }
        } else {
            $(`#${staffModalName}`).modal('hide');
            this.getStaffData();
        }
    }

    async openAddStaffModal() {
        await this.setState({
            staffForm: {
                mode: 'add',
                data: this.initializeStaffFormData()
            }
        })
        $(`#${staffModalName}`).modal('show');
    }

    async deleteStaff(staffId) {
        let confirm = window.confirm("Are you sure want to delete this entry!");
        if (confirm) {
            const resData = await deleteEntity('staffs', staffId).catch((res) => {
                console.log(res);
            })

            if (resData) {
                console.log(resData);
                this.getStaffData();
            }
        }
    }

    async editStaff(staffId) {
        // get student data using student id
        const reqStu = this.state.staffList.find((item) => item[staffUniqueKey] === staffId);
        if (reqStu) {
            // initialize form with prefill data
            await this.setState({
                staffForm: {
                    mode: 'edit',
                    data: this.initializeStaffFormData(reqStu)
                },
                keyValChange: this.resetChangedKeys()
            });
            $(`#${staffModalName}`).modal('show');
        }
    }

    async handleBulkInsert(excelData) {
        await this.setState({ loading: true })
        const studentData = await addEntityData('staffs', convertEntityExcelData(excelData)).catch((err) => {
            console.log(err);
        })
        if (studentData) {
            this.getStaffData();
        }
    }

    render() {
        const { batchList, errors, staffForm, rolesData } = this.state;
        return (
            <>
                <Entity
                    entityRowsKeys={staffRowsKeys} entityHeaderKeys={staffHeaderKeys}
                    uniqueKey={staffUniqueKey} entityName='Staff'
                    modalName={staffModalName} entityDataList={this.state.staffList}
                    entityExcelKeys={staffExcelKeys} loading={this.state.loading}
                    addBtnDisabled={batchList.length > 0 ? false : true}
                    addEntity={this.openAddStaffModal.bind(this)}
                    deleteEntity={this.deleteStaff.bind(this)}
                    editEntity={this.editStaff.bind(this)}
                    handleBulkInsert={this.handleBulkInsert.bind(this)}
                    editAction={true}
                />

                <div className="modal fade" id={staffModalName} tabIndex="-1" aria-labelledby="StaffModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="StaffModalLabel">Add Staff Details</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.onStaffFormSubmit.bind(this)}>
                                    <div style={{ marginBottom: '16px' }}>
                                        <div style={{ marginBottom: '8px' }}> Photo </div>
                                        <input type="file" accept=".png, .jpg" className="form-control-file" id="staffPhoto"
                                            ref={staffForm.data[staffKeys.photo]}></input>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label htmlFor="firstname">First Name*</label>
                                            <input type="text" className="form-control" id="firstname" placeholder="First Name" maxLength="15" required
                                                value={staffForm.data[staffKeys.firstName]} onChange={this.handleChange.bind(this, staffKeys.firstName)}
                                            />
                                            {errors.firstName.length > 0 &&
                                                <span className='error'>{errors.firstName}</span>}

                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="lastname">Last Name</label>
                                            <input type="text" className="form-control" id="lastname" placeholder="Last Name" maxLength="15"
                                                value={staffForm.data[staffKeys.lastName]} onChange={this.handleChange.bind(this, staffKeys.lastName)}
                                            />
                                            {errors.lastName.length > 0 &&
                                                <span className='error'>{errors.lastName}</span>}
                                        </div>
                                        
                                        <div className="form-group col-md-4">
                                            <label htmlFor="dob">Birth Date*</label>
                                            <input type="date" className="form-control" id="dob" placeholder="dob" required min={this.minBirthDate} max={this.maxBirthDate}
                                                value={staffForm.data[staffKeys.dob]} onChange={this.handleChange.bind(this, staffKeys.dob)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-5">
                                            <label htmlFor="email">Email*</label>
                                            <input type="email" className="form-control" id="email" placeholder="Email" required
                                                value={staffForm.data[staffKeys.email]} onChange={this.handleChange.bind(this, staffKeys.email)}
                                            />
                                            {errors.email.length > 0 &&
                                                <span className='error'>{errors.email}</span>}

                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="mobile">Mobile*</label>
                                            <input type="text" className="form-control" id="mobile" placeholder="Mobile No." required
                                                value={staffForm.data[staffKeys.mobile]} onChange={this.handleChange.bind(this, staffKeys.mobile)}
                                            />
                                            {errors.mobile.length > 0 &&
                                                <span className='error'>{errors.mobile}</span>}
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="designation"> Designation</label>
                                            <select id="designation" className="form-control"
                                                value={staffForm.data[staffKeys.designation]} onChange={this.handleChange.bind(this, staffKeys.designation)}
                                            >
                                                <option value=""> Select </option>
                                                <option> C1 </option>
                                                <option> C2 </option>
                                                <option> C3 </option>
                                                <option> A1 </option>
                                                <option> A2 </option>
                                                <option> A3 </option>

                                            </select>
                                        </div>
                                    </div>
                                                    
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label htmlFor="bloodgroup"> Blood Group*</label>
                                            <select id="bloodgroup" className="form-control"
                                                value={staffForm.data[staffKeys.bloodGroup]} onChange={this.handleChange.bind(this, staffKeys.bloodGroup)}
                                            >

                                                <option value=""> Select </option>
                                                {
                                                    this.bloodGroupList.map((item) => {
                                                        return <option value={item} key={item}> {item} </option>
                                                    })
                                                }

                                            </select>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="education"> Education*</label>
                                            <select id="education" className="form-control" required
                                                value={staffForm.data[staffKeys.education]} onChange={this.handleChange.bind(this, staffKeys.education)}
                                            >
                                                <option value=""> Select </option>
                                                <option> SSC (10th) </option>
                                                <option> HSC (12th) </option>
                                                <option> Diploma </option>
                                                <option> Medical Diploma </option>
                                                <option> MBBS/BAMS/BHMS </option>
                                                <option> BSc </option>
                                                <option> BA </option>
                                                <option> BCom </option>
                                                <option> B.E. </option>
                                                <option> B.Tech </option>
                                                <option> LLB </option>
                                                <option> B.Ed </option>
                                                <option> B.Pharm </option>
                                                <option> BMS/BBA/BBS </option>
                                            </select>

                                        </div>
                                        
                                        <div className="form-group col-md-4">
                                            <label htmlFor="football_education">Football Education*</label>
                                            <input type="text" className="form-control" id="football_education" placeholder="Football education" maxLength="25" required
                                                value={staffForm.data[staffKeys.footballEducation]} onChange={this.handleChange.bind(this, staffKeys.footballEducation)}
                                            />
                                            {errors.football_education.length > 0 &&
                                                <span className='error'>{errors.football_education}</span>}

                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label htmlFor="role">Role</label>
                                            <select id="role" className="form-control selectpicker" data-live-search="true" required
                                            value={staffForm.data[staffKeys.role]} onChange={this.handleChange.bind(this, staffKeys.role)}>
                                                <option value=""> Select </option>
                                                {
                                                    rolesData.map((item) => {
                                                        return <option value={item.category} key={item.category}> {item.category} </option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="specialisation">Specialisation</label>
                                            <input type="text" className="form-control" id="specialisation" placeholder="eg. MBA" maxLength="25"
                                                value={staffForm.data[staffKeys.specialisation]} onChange={this.handleChange.bind(this, staffKeys.specialisation)}
                                            />
                                            {errors.specialisation.length > 0 &&
                                                <span className='error'>{errors.specialisation}</span>}
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="Batches"> Batches *</label>
                                            <select id="batches" multiple className="form-control selectpicker" data-live-search="true" required
                                                value={staffForm.data[staffKeys.batches]} onChange={this.handleChange.bind(this, staffKeys.batches)}
                                            >
                                                {
                                                    batchList.map((item) => {
                                                        return <option value={item.code} key={item.code}> {item.code} </option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="address">Address*</label>
                                        <input type="text" className="form-control" id="address" placeholder="1234 Main St" maxLength="50" required
                                            value={staffForm.data[staffKeys.address]} onChange={this.handleChange.bind(this, staffKeys.address)}
                                        />
                                    </div>
                                    <div className="form-row align-items-center">
                                        {this.state.staffForm.mode === 'add' && <div className="form-group col-md-6">
                                            <label htmlFor="joiningdate">Joining Date</label>
                                            <input type="date" className="form-control" id="joiningdate" placeholder="Joining Date" required min={this.minJoiningDate} max={this.maxJoiningDate}
                                                value={staffForm.data[staffKeys.joiningDate]} onChange={this.handleChange.bind(this, staffKeys.joiningDate)}
                                            />
                                        </div>}
                                        <div className="col-md-6 text-center">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" name="adminRadio" id="adminRadio" checked={staffForm.data[staffKeys.isAdmin] === 'true'}
                                                    value={staffForm.data[staffKeys.isAdmin] === 'true' ? 'false' : 'true'} onChange={this.handleIsAdminChange.bind(this)}
                                                />
                                                <label className="form-check-label" htmlFor="adminRadio">
                                                    Is Admin?
                                                </label>
                                            </div>
                                        </div>
                                        {/* <div className="d-flex col-md-6 align-items-center">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="joiningMethodRadios" id="onlineRadio" value="online"
                                                    checked={staffForm.data[staffKeys.joiningMethod] === 'online'} onChange={this.handleChange.bind(this, staffKeys.joiningMethod)}
                                                />
                                                <label className="form-check-label" htmlFor="onlineRadio">
                                                    Online Joining
                                                </label>
                                            </div>
                                            <div className="form-check ml-2">
                                                <input className="form-check-input" type="radio" name="joiningMethodRadios" id="offlineRadio" value="offline"
                                                    checked={staffForm.data[staffKeys.joiningMethod] === 'offline'} onChange={this.handleChange.bind(this, staffKeys.joiningMethod)}
                                                />
                                                <label className="form-check-label" htmlFor="offlineRadio">
                                                    Offline Joining
                                                 </label>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div>
                                        <button className="btn btn-success w-100" type="submit"> Submit </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}