import React from 'react';
import './player-wise.scss';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getEntityData, getStudentSummary, getStudentTransactionHistory } from "services/entity-apis";
import { studentKeys } from 'config/entity.config';
import { convertEpochToDate } from "services/shared-logic.service.js";
import { downloadPaymentReceipt } from 'services/payment.service';

export default class PlayerWise extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            tableData: {
                0: {
                    absent: "-", present: "-", late: "-", total: "-"
                },
                1: {
                    absent: "-", present: "-", late: "-", total: "-"
                },
                2: {
                    absent: "-", present: "-", late: "-", total: "-"
                },
                3: {
                    absent: "-", present: "-", late: "-", total: "-"
                },
                4: {
                    absent: "-", present: "-", late: "-", total: "-"
                },
                5: {
                    absent: "-", present: "-", late: "-", total: "-"
                },
                6: {
                    absent: "-", present: "-", late: "-", total: "-"
                },
                7: {
                    absent: "-", present: "-", late: "-", total: "-"
                },
                8: {
                    absent: "-", present: "-", late: "-", total: "-"
                },
                9: {
                    absent: "-", present: "-", late: "-", total: "-"
                },
                10: {
                    absent: "-", present: "-", late: "-", total: "-"
                },
                11: {
                    absent: "-", present: "-", late: "-", total: "-"
                }
            },
            transactionData: [],
            organizationData: null,
            year: new Date().getFullYear(),
            selectedStudent: null,
            dataProgress: true
        }
    }

    componentDidMount() {
        this.getOrganizationData();
        this.getStudentTransactionHistory(this.props.student);
    }

    async getOrganizationData() {
        const data = await getEntityData('organizations').catch((err) => {
            console.log(err);
        })

        if (data) {
            await this.setState({
                organizationData: data.data || {},
            })
        }
    }

    async getStudentTransactionHistory(student) {
        this.setSelectedStudent(student);

        const studentTransactionData = await getStudentTransactionHistory(student[studentKeys.studentId]).catch(err => {
            console.log(err);
        })

        if (studentTransactionData) {
            this.setState({
                transactionData: studentTransactionData.data
            })
        }

    }


    async setSelectedStudent(student) {
        this.setState({
            year: this.props.year,
            selectedStudent: student,
            dataProgress: true
        })

        for (let i = 11; i >= 0; i--) {
            const year = this.state.year;
            const startTm = +new Date(year, i, 1);
            const endTm = +new Date(year, i + 1, 0);
            const summaryData = await getStudentSummary(student[studentKeys.studentId], endTm / 1000, startTm / 1000).catch(err => {
                console.log(err);
            })

            if (summaryData) {
                const present = summaryData.data.find((item) => item.status === 'PRESENT')?.count,
                    absent = summaryData.data.find((item) => item.status === 'ABSENT')?.count,
                    late = summaryData.data.find((item) => item.status === 'LATE')?.count,
                    total = +(present || 0) + +(absent || 0) + +(late || 0);
                this.setState({
                    tableData: {
                        ...this.state.tableData,
                        [i]: {
                            present,
                            absent,
                            late,
                            total
                        }
                    },
                    dataProgress: false
                })
            }
        }
    }

    async onYearChange(year) {
        await this.setState({
            year
        });
        if (this.state.selectedStudent) {
            this.setSelectedStudent(this.state.selectedStudent);
        }
    }

    render() {
        const { tableData, selectedStudent, dataProgress, organizationData } = this.state;

        return (
            <>
                {dataProgress && <div className="d-flex justify-content-center p-4">
                    <div id="preloader">
                        <div id="loader"></div>
                    </div>
                </div>}
                {!dataProgress && <div className="container mt-2">
                    <div className="mt-2">
                        <Table striped bordered hover>
                            <thead>
                                <tr>

                                    <th rowSpan="2"> {selectedStudent ? (selectedStudent[studentKeys.firstName] + ' ' + selectedStudent[studentKeys.lastName]) : "Student name"} </th>
                                    <th colSpan="3"> Q1 </th>
                                    <th colSpan="3"> Q2 </th>
                                    <th colSpan="3"> Q3 </th>
                                    <th colSpan="3"> Q4 </th>
                                </tr>
                                <tr>
                                    <th> Jan </th>
                                    <th> Feb </th>
                                    <th> Mar </th>
                                    <th> Apr </th>
                                    <th> May </th>
                                    <th> Jun </th>
                                    <th> Jul </th>
                                    <th> Aug </th>
                                    <th> Sept </th>
                                    <th> Oct </th>
                                    <th> Nov </th>
                                    <th> Dec </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Present</td>
                                    {
                                        Array(12).fill(0).map((item, index) => {
                                            return (
                                                <td key={index}>{tableData[index].present === undefined ? '-' : tableData[index].present}</td>
                                            )
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td>Absent</td>
                                    {
                                        Array(12).fill(0).map((item, index) => {
                                            return (
                                                <td key={index}>{tableData[index].absent === undefined ? '-' : tableData[index].absent}</td>
                                            )
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td>Late</td>
                                    {
                                        Array(12).fill(0).map((item, index) => {
                                            return (
                                                <td key={index}>{tableData[index].late === undefined ? '-' : tableData[index].late}</td>
                                            )
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td>Total</td>
                                    {
                                        Array(12).fill(0).map((item, index) => {
                                            return (
                                                <td key={index}>{tableData[index].total === undefined ? '-' : tableData[index].total}</td>
                                            )
                                        })
                                    }
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="mt-2">

                        <h3 className="my-4"> Payment Summary  {selectedStudent ? 'for ' + (selectedStudent[studentKeys.firstName] + ' ' + selectedStudent[studentKeys.lastName]) : ""} </h3>
                        <Table striped bordered hover>
                            <thead>

                                <tr>
                                    <th> Amount </th>
                                    <th> Transaction Date </th>
                                    <th> Payment Mode </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.transactionData.map((transaction, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>₹{transaction.transactions_amount}</td>
                                                <td>{convertEpochToDate(transaction.transactions_date * 1000)}</td>
                                                <td>{transaction.transactions_mode}</td>
                                                <td>
                                                    <button className="border-0 bg-transparent" title="Download Receipt"
                                                        onClick={downloadPaymentReceipt.bind(this, selectedStudent, transaction, organizationData)}>
                                                        <FontAwesomeIcon icon={["fas", "file-pdf"]} color="red" size="lg" />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>

                </div>
                }
            </>
        )
    }
}