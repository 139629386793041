import React from 'react';
import './params-table.scss';

export default class ParamsTable extends React.Component {


    assessOrder1 = ['Technical', 'Physical', 'Game',
        'Game Insights', 'Personality', 'Goalkeeping'];

    assessOrder2 = ['Technical', 'Physical', 'Game Insights',
        'Personality', 'Game'];

    gradeMap = {
        1: '⭐',
        2: '⭐⭐',
        3: '⭐⭐⭐'
    }

    createDataTableHeader(item) {
        return (
            <tr>
                <th scope="col" className="params"> {item} </th>
                <th scope="col" className="values"> Grade </th>
            </tr>
        )
    }

    createDataTableBody(data) {
        return Object.keys(data).map((item) => {
            return (
                <tr key={item}>
                    <td className="params">
                        {item}
                    </td>
                    <td className="values">
                        {this.gradeMap[data[item]]}
                    </td>
                </tr>
            )
        })
    }

    render() {
        let arr = this.assessOrder1;
        if (this.props.isGoalKeeping !== 'true') {
            arr = this.assessOrder2;
        }
        return (
            <>
                <section className="legends-wrapper">
                    <div className="">
                        ⭐ - Needs Improvement
                    </div>
                    <div className="">
                        ⭐⭐ - Improving
                    </div>
                    <div className="">
                        ⭐⭐⭐ - Excellent
                    </div>
                </section>
                <div className="table-sec" id="divToPrint">
                    {
                        Object.keys(this.props.filteredAssessmentData.data).sort((a, b) => {
                            return arr.indexOf(a) - arr.indexOf(b);
                        }).filter(item => {
                            return (this.props.isGoalKeeping !== 'true') ? item !== 'Goalkeeping' : true
                        }).map((item) => {
                            return (
                                <div className="table-responsive mt-2" key={item}
                                    style={{ overflow: 'hidden' }}>
                                    <table className="table table-bordered">
                                        <thead className="">
                                            {this.createDataTableHeader(item)}
                                        </thead>
                                        <tbody>
                                            {this.createDataTableBody(this.props.filteredAssessmentData.data[item])}
                                        </tbody>
                                    </table>
                                </div>
                            )
                        })
                    }
                </div>
                <section className="assess-footer">
                    <div> <i>Coach's Feedback</i> </div>
                    <hr />
                    <div className="comment-box"> {this.props.filteredAssessmentData.comments} </div>
                </section>
            </>
        )
    }
}