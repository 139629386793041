import React from 'react';
import './history-modal.scss';
import { getBatchData, getBatchWiseData, fetchStudentAttendance } from "services/entity-apis";
import { batchKeys, studentAttendanceKeys, studentKeys, weekDays } from 'config/entity.config';
import { convertEpochToDate } from 'services/shared-logic.service';

export default class HistoryModal extends React.Component {

    possibleStatuses = ['absent', 'present_in_first', 'present_in_second', 'present', 'not_defined'];

    constructor(props) {
        super(props);
        this.state = this.initializeState();
    }

    initializeState() {
        const currDate = new Date();
        const currYr = currDate.getFullYear();
        let currMon = currDate.getMonth() + 1;
        currMon = currMon > 9 ? currMon : `0${currMon}`;

        return {
            attendanceData: [],
            batchDetails: null,
            batchStudents: [],
            tableHeaders: [],
            tableBodyData: {},
            attendancePeriod: {
                startTime: null,
                endTime: null,
                month: `${currYr}-${currMon}`
            },
            progress: true
        };
    }

    componentDidMount() {
        this.props.setOpen(this.onOpen.bind(this));
    }

    onOpen() {
        this.setState(this.initializeState());

        const currDate = new Date();
        const startTm = +new Date(currDate.getFullYear(), currDate.getMonth(), 1);
        const endTm = +new Date(currDate.getFullYear(), currDate.getMonth() + 1, 0);

        this.setState({
            attendancePeriod: {
                startTime: startTm,
                endTime: endTm,
                month: this.state.attendancePeriod.month
            }
        })

        Promise.all([this.getBatchDetails(), this.getAttendanceTelemetry(Math.floor(startTm / 1000), Math.floor(endTm / 1000)),
        this.getStudentListBatchWise()]).then(res => {
            this.createTableHeaders(Math.floor(startTm / 1000), Math.floor(endTm / 1000));
        })
    }

    shiftAttendancePeriod() {
        const { startTime, endTime } = this.state.attendancePeriod;
        this.getAttendanceTelemetry(Math.floor(startTime / 1000), Math.floor(endTime / 1000)).then(res => {
            this.createTableHeaders(Math.floor(startTime / 1000), Math.floor(endTime / 1000));
        })
    }

    async createTableHeaders(strttm, endtm) {
        const headerArr = ['Player Name'];
        for (let startTime = strttm; startTime <= endtm; startTime += 604800) {
            let prevDay = null;
            this.state.batchDetails[batchKeys.dayTime].forEach(dayTime => {
                if (dayTime[batchKeys.day] !== prevDay) {
                    const currDay = new Date(startTime * 1000).getDay();
                    const batchDay = weekDays.indexOf(dayTime[batchKeys.day]);
                    let reqTm = null;
                    if (currDay - batchDay <= 0) {
                        reqTm = new Date(startTime * 1000).setDate(new Date(startTime * 1000).getDate() + (batchDay - currDay));
                    } else {
                        reqTm = new Date(startTime * 1000).setDate(new Date(startTime * 1000).getDate() + (7 - (currDay - batchDay)));
                    }
                    if (Math.floor(reqTm / 1000) <= endtm) {
                        headerArr.push(reqTm);
                    }
                    prevDay = dayTime[batchKeys.day];
                }
            });
        }
        await this.setState({
            tableHeaders: [headerArr[0], ...headerArr.slice(1).sort()]
        })
        this.createTableBodyData(this.state.tableHeaders);
    }

    createTableBodyData(headerArr) {
        const initialObj = this.state.batchStudents.reduce((prev, curr) => {
            prev[curr[studentKeys.studentId]] = headerArr.slice(1).reduce((prev1, curr1) => {
                prev1[curr1] = 4
                return prev1;
            }, {});
            return prev;
        }, {});
        this.state.attendanceData.forEach(item => {
            if (this.state.batchStudents.find(stu => stu[studentKeys.studentId] === item[studentAttendanceKeys.studentId])) {
            const processedEpoch = new Date(item[studentAttendanceKeys.date] * 1000).setHours(0, 0, 0, 0);
            const statusIdx = item[studentAttendanceKeys.status] === 'PRESENT' ? 3 : 0;
            const prevVal = initialObj[item[studentAttendanceKeys.studentId]][processedEpoch];
            if (prevVal === 4 || (prevVal === statusIdx)) {
                initialObj[item[studentAttendanceKeys.studentId]][processedEpoch] = statusIdx;
            } else if (prevVal !== undefined) {
                initialObj[item[studentAttendanceKeys.studentId]][processedEpoch] = prevVal === 0 ? 2 : 1;
            }
        }
        })
        this.setState({
            tableBodyData: initialObj,
            progress: false
        })
    }

    async getBatchDetails() {
        const batchData = await getBatchData(this.props.batch).catch((err) => {
            console.log(err);
        })

        if (batchData) {
            await this.setState({
                batchDetails: batchData.data
            })
        }
    }

    async getAttendanceTelemetry(from, to) {
        const reqObj = {
            from,
            to,
            batch: this.props.batch
        }
        const attenData = await fetchStudentAttendance(reqObj).catch((err) => {
            console.log(err);
        })

        if (attenData && attenData.data && attenData.data.length) {
            await this.setState({
                attendanceData: attenData.data
            })
        }
    }

    async getStudentListBatchWise() {
        const stuData = await getBatchWiseData('students', this.props.batch).catch((err) => {
            console.log(err);
        })

        if (stuData && stuData.data && stuData.data.length) {
            await this.setState({
                batchStudents: stuData.data || []
            })
        }
    }

    createEntityDataTableBody() {
        return this.state.batchStudents?.map((entity, idx) => {
            return (
                <tr className="table-row" key={entity[studentKeys.studentId]}>
                    <td> {entity[studentKeys.firstName]} {entity[studentKeys.lastName]} </td>
                    {
                        this.state.tableHeaders.slice(1).map((key, index) => {
                            return (
                                this.state.tableBodyData[entity[studentKeys.studentId]] && <td key={index}>
                                    {
                                        this.state.tableBodyData[entity[studentKeys.studentId]] &&
                                        <div className={`attend-circle ${this.possibleStatuses[this.state.tableBodyData[entity[studentKeys.studentId]][key]]}`}></div>
                                    }
                                </td>
                            )
                        })
                    }
                </tr>
            )
        })
    }

    createEntityDataTableHeader() {
        return (
            <tr>
                {
                    this.state.tableHeaders.map((key, index) => {
                        return (
                            <th scope="col" key={index}>
                                {
                                    index === 0 ? key : convertEpochToDate(key)
                                }
                            </th>
                        )
                    })
                }
            </tr>
        )
    }

    async onMonthChange(event) {
        const tempArr = event.target.value.split('-');
        const { year, month } = {
            year: tempArr[0],
            month: tempArr[1]
        }
        const firstDay = +new Date(year, month - 1, 1);
        const lastDay = +new Date(year, month, 0);
        await this.setState({
            attendancePeriod: {
                startTime: firstDay,
                endTime: lastDay,
                month: event.target.value
            },
            progress: true
        })
        this.shiftAttendancePeriod();
    }

    render() {

        const sliderDiv =
            <>
                <input type="month" onChange={this.onMonthChange.bind(this)} value={this.state.attendancePeriod.month} />
            </>

        return (
            <div className="modal fade" id="historyModal" tabIndex="-1" aria-labelledby="historyModalLabel" aria-hidden="true">
                <div className="modal-dialog" style={{ maxWidth: "1000px" }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="historyModalLabel">Attendance</h5>
                            <div className="date-slider ml-4 d-none d-md-flex">
                                {sliderDiv}
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="date-slider d-flex d-md-none m-auto">
                            {sliderDiv}
                        </div>
                        <div className="modal-body">
                            {
                                this.state.progress &&
                                <div className="d-flex justify-content-center p-4">
                                    <div className="dot-flashing"> </div>
                                </div>
                            }
                            {
                                !this.state.progress &&
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead className="table-head">
                                            {this.createEntityDataTableHeader()}
                                        </thead>
                                        <tbody className="table-body">
                                            {this.createEntityDataTableBody()}
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}